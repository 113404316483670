@import url('https://fonts.googleapis.com/css2?family=Almendra:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Emilys+Candy&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.dob-placeholder {
    position: absolute;
}

.signup_content {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: url('AlbedoBase_XL_create_an_image_of_dark_night_theme_of_a_carniva_0.webp') no-repeat center center fixed;
    background-size: cover;
    width: 100vw;
}

.signup-body {
    width: 70vh;
    height: 510px;
    background: url('scroll3.webp') no-repeat;
    background-position: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 15px 0 10px;
    margin-top: 4em;
}


.signup-form-cont {
    width: 270px;
    max-height: 50%;
    text-align: center;
    overflow: scroll;
    margin-top: 5em; 
    scrollbar-width: thin;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
}

.signup-form-cont::-webkit-scrollbar {
    width: 10px;
    
    scrollbar-width: thin;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
}

.signup-form-cont::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(82, 68, 68);
    border-radius: 10px;
}

.signup-form-cont::-webkit-scrollbar-thumb {
    background-image: url('star-removebg-preview.png');

    background-size: contain;
    
    background-repeat: no-repeat;

    border-radius: 10px;
   
}

.signup-form-cont::-webkit-scrollbar-thumb:hover {
    background: #757028;
}

.signup-heading {

    padding-bottom: 0;
    font-size: 2em;
    font-family: 'Almendra', serif;
    color: rgb(7, 7, 7);
    margin-bottom: -2.5em;

    margin-top: 2.3em;

}

#signup-dob {
    position: relative;
}

.dob-placeholder {
    display: none;
}

.signup-inputfield input {
    width: 67%;
    background: white;
    box-shadow: 0px 0px 8px rgb(194, 121, 3);
    border: 2px solid rgba(2, 2, 2, 0.2);
    border-radius: 20px;
    font-size: 16px;
    color: #090909;
    padding: 10px 15px 5px 10px;
    margin-top: 15px;
}

.signup-inputfield select {
    width: 67%;
    box-shadow: 0px 0px 8px rgb(194, 121, 3);
    background: white;
    border: 2px solid rgba(1, 1, 1, 0.2);
    border-radius: 20px;
    font-size: 16px;
    color: #131111;
    padding: 10px 15px 5px 10px;
    margin-top: 15px;
    font-family: "Modern Antiqua";
    font-weight: 590;
}

.signup-inputfield input::placeholder {
    color: #080707;
    font-family: "Modern Antiqua";
    font-weight: 590;
}

.signup-button {
    padding: 0.7em;
    min-width: 9em;
    margin-top: 3.2em;
    border-radius: 10px;
    font-size: 1em;
    border: none;
    color: #d9d9d9;
    background-color: rgb(11, 9, 9);
    font-family: "Modern Antiqua";
    font-weight: 590;
    cursor: pointer;
}

.error-message-signup {
    color: #ff6363;
    font-size: 0.85em;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 5.5em;
    font-family: "Modern Antiqua";
}

.captcha {
    transform: scale(0.8);
    transform-origin: 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
    height: 35px;
    margin-left: 3.6em;
    border-radius: 20px;

}

.new-user {
    margin-top: 1em;
    color: black;
    margin-bottom: 3em;
    font-family: "Modern Antiqua";
}

.heading {
    color: rgb(102, 0, 139);
    display: flex;
    justify-content: center;
    margin-top: 1em;
    font-weight: 700;
    font-size: 18px;
}

@media(max-width: 1300px) {
    .signup-body {
        width: 60vh;
    }

    .signup-form-cont {
        width: 280px;
        max-height: 50%;
        text-align: center;
        overflow: auto;
        margin-top: 5em;
        scrollbar-width: thin;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-x: hidden;
    }

    .captcha {
        margin-left: 4.2em;
        margin-top: 2em;
    }

    .signup-button {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .signup-inputfield input {
        width: 70%;
    }

    .signup-inputfield select {
        width: 70%;
    }

    .new-user {
        margin-bottom: 2em;
    }
}

@media(max-width: 1100px) and (min-width: 517px) {

    .signup-form-cont {
        width: 280px;
        max-height: 50%;
        text-align: center;
        overflow: auto;
        margin-top: 5em;
        scrollbar-width: thin;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-x: hidden;
    }

    .signup-inputfield input,
    .signup-inputfield select {
        width: 15em;
        border-radius: 15px;
    }


    .signup-body {
        width: 480px;
    }

    .captcha {
        margin-left: 5em;
        transform: scale(0.7);
        transform-origin: 0 0;
    }

    .signup-button {
        width: 25%;
    }

}

@media(max-width: 600px) {
    .signup-body {
        margin-bottom: 8em;
    }
}

@media(max-width: 517px) {

    .dob-placeholder {
        display: block;
        position: absolute;
        color: black;
        font-size: 0.7rem;
        top: 1.7rem;
        opacity: 0.7;
        left: 10.4rem;
    }

    .signup_content {
        margin: 0;
        padding: 0;
    }

    .signup-body {
        width: 380px;
        height: 560px;
        padding: 15px;
        background: url(scroll\ 2.1.webp) no-repeat;
        /* background-position: center; */
        margin-top: 3.5em;
        transform: scale(1.14);
        background-size: cover;
        overflow: hidden;
       
    }
    
    .captcha {
        transform: scale(0.5);
        transform-origin: 0 0;
        margin-left: 10em;
        margin-top: 2em;

    }

    .new-user {
        width: 70%;
    }

    .signup-heading {
        font-size: 22px;
        margin-bottom: -3.5em;
        margin-top: 4.2em;

    }

    .signup-form-cont {
        width: 200px;
        max-height: 56%;
        text-align: center;
        overflow: auto;
        margin-top: 5em;
        scrollbar-width: thin;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-x: hidden;

    }

    .heading {
        font-size: 14px;
        margin-left: .5em;
    }

    .signup-inputfield input,
    .signup-inputfield select {
        width: 35%;
        font-size: 12px;
        border: 1px solid #0b0909;
        border-radius: 15px;
    }

    .dateplace {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #131111;
        z-index: 10 !important;
        padding-top: 15px;
        display: flex !important;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        opacity: 0.7;
        text-align: left;
        font-weight: 600;
        line-height: normal;
        word-spacing: normal;
        letter-spacing: normal;
    }

    .dateplace span {
        opacity: 0.8;
    }

    .error-message-signup{
        color: red;
        font-size: 10px;
        position: absolute;
        right: 32.5%;
        margin: 0 !important;
    }
    .signup-inputfield{
        position: relative;
    }

    .emptyfield {
        position: relative;

    }

    .flatpickr-calendar {
        font-size: 14px;
    }

    .error-message {
        color: #ff6363;
        font-size: 0.72em;
        margin-top: 3px;
        margin-right: 13em;
        display: flex;
        align-items: end;
        justify-content: end;

    }

    .signup-button {
        min-width: 6em;
        font-size: 15px;
        margin-top: 1em;
    }
}

#dob {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    position: relative;
}


#dob:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}

.dateplace {
    display: none;
    z-index: -1;
}

#city {
    opacity: 1;
}

.birthhidden {
    display: none;
    z-index: -1 !important;
}
.sup-btn-div{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}