.back-navigation-button-container{
    position: fixed;
    margin: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    background-color: transparent;
    backdrop-filter: blur(10px);
    border-radius: 5rem;
}

.back-navigation-button{
    background-color: #07070757;
    backdrop-filter: blur(10px);
    color: white;
    border-radius: 5rem;
    cursor: pointer;
    border-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.back-navigation-button-img{
    width: 2em;
}

@media screen and (max-width: 830px) {
    .back-navigation-button-container{
        margin: 0.5em 0.5em;
    }
}