@import url('https://fonts.googleapis.com/css2?family=Emilys+Candy&family=Modern+Antiqua&display=swap');

.contingent-body{
    font-family: 'Modern Antiqua', serif !important;
    background-repeat: no-repeat;
    background-size: cover;
    display:flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    height:100vh;
    gap:20px;
    width:100vw;
    position: fixed;
    padding-top: 160px;
    position: fixed;
}

.contingent_payment{
    min-height:50vh;
    max-height: 65vh;
    padding-top:20px;
    background: rgba(1, 1, 9, 0.393); 
    border-radius: 10px;
    backdrop-filter: blur(12px);
    border: 0.3px solid rgb(241, 241, 243);
    color:white;
    border-radius: 10px;
    overflow: auto;
    width:360px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 10px;
    align-items: center;
    position: relative;
}
.cont-pay{
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 20px !important;
}

.cont-payment-button{
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    cursor: pointer;
}



.contingent_payment form{
    width:100%;
}

.cont-pay input ,.cont-pay select{
   width:100% !important;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    text-align: center;
    padding:8px;
}

.add-mem-closebutton{
    position: absolute;
    top: 0px;
    right: 10px;
    color: white;
    background: none;
    border: none;
    font-size: 2.5rem;
    cursor: pointer;
}
.add-member-select{
    width: 20% !important;
}

.input-field{
    
    width: 90%;
}

.contingent-head{
    color: white;
    font-size: 30px;
    font-weight: 600;
    padding-top:15px;
    text-shadow: 1px 1px 10px wheat
    /* margin-top: 80px; */
}
.contingent-form{
    color: white;
    background-color: rgba(1, 1, 9, 0.737);
    backdrop-filter: blur(12px);
    padding:0px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap:10px;
    width: 360px;
    height: 380px;
    /* border: 0.5px solid #90e1efd0; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.contingent-main-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:0px;
    position: relative;
    cursor: pointer;
}

.cont-opt-btn{
    all: unset;
    width: 180px;
    /* cursor: ; */
    text-align: center; 
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    height: 50px;
    border-bottom: 1px solid wheat;
    z-index: 2;
    border-radius: 0 10px 0px 0px;
    /* text-shadow: 1px 1px 5px black; */
    
}
.cont-field:-webkit-autofill,
.cont-field:-webkit-autofill:hover, 
.cont-field:-webkit-autofill:focus
{
    -webkit-text-fill-color: #ffffff;
    caret-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
   
}



.cont-opt-bg{
    position: absolute;
    left: 0;
    border-radius: 10px 0 0 0 !important;
    background-color: wheat;
    z-index: 0;
    transition: all 0.5s ease;
}
.cont-move{
    position: absolute;
    left: 50%;
    border-radius: 0 10px 0 0 !important;
    /* background-color: black;
    color: white; */
    z-index: 0;
}

.contingent-main-buttons :first-child{
    border-radius:10px 0 0 0;
    border-right: 1px solid wheat;
    cursor: pointer;
}
.contingent-main-buttons :last-child{
    border-radius:0 10px 0 0;
    cursor: pointer;
}
.cont-color{
    color: black;
    background-color: wheat;

}
.cont-color2{
  border-radius: 10px;
}

.cont-select{
    background-color: #501E5D;
}

.contingent-form-subform{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:20px;
    padding: 10px;
    height: 250px;
    padding-top: 50px;
    padding-bottom: 30px;
}
.cont-input-box{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    justify-content: start;
    padding: 0 10px;
    margin-top: 10px;;
}
.cont-label{
    font-size: 20px;
    margin-left: 5px;
    display: block;
    /* padding-bottom: 10px; */
    padding-top: 10px;
    margin-bottom:5px ;
}
.cont-field{
    height: 40px;
    margin-top: 2px;
    border-radius: 5px;
    padding: 0 10px ;
    width: 100%;
    font-size: 15px;
    background: rgba(1, 1, 9, 0.737);
    color: white;
    border-bottom: 1px solid wheat;
    border-right:1px solid wheat;
    transition: all 0.5s ease;
    color: white;
    /* margin-top: 10px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

}

.cont-field:focus{
    outline: none;
    /* color: rgb(255, 255, 255); */
    background: rgba(1, 1, 9, 0.737);
    /* box-shadow: inset 7px 7px 17px #501E5D,
    inset -7px -7px 17px #501E5D;; */
}

.cont-submit-button{
    margin-top: 10px;
    width: 100%;
    padding: 2px;
    text-align: center;
    z-index: 2;
    cursor: pointer;
}

.cont-sub{
  all: unset;
  position: relative;
  display: inline-block !important;
    padding: 15px;
  /* width: 100%; */
  text-align: center;
border-radius: 10px;
color: black;
background-color: wheat;
/* box-shadow: 2px 2px 12px #a928c9,
-2px -2px 12px #a928c9; */
animation: moveBorder 1s ease;
z-index: 2;
position: relative;
}
.cont-sub:active{
    transform: scale(0.9);
}
.contingent-leader-modal{
   
    background-color: rgba(1, 1, 9, 0.302);
    backdrop-filter: blur(12px);
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    border:none;
    width:60vw;
    padding-bottom: 20px;
    /* max-height: 70vh; */
    /* position: relative;
    overflow: auto; */
    margin-bottom: 250px;
}


.contingent-leader-modal-contname{
    text-align: center;
    /* margin-bottom: 10px; */
    background-color: wheat;
    color: black;
    /* padding-bottom: 10px; */
    padding-top: 10px;
    border-radius: 10px 10px 0px 0px;
    height: 50px;
    width: 100%;
}
.contingent-leader-modal-paymentbutton{
    height: 50px;
    width: 150px;
    border-radius: 5px;
    background-color: green;
    border: none;
    color: white;
    font-weight: 900;
    cursor: pointer;
}
.contingent-leader-modal-content{
    
    display: flex;
    flex-direction: column;
    justify-content:space-evenly;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    scrollbar-width: 0px;
    height: 50vh;
    overflow: auto;
   
}

.contingent-leader-modal-content::-webkit-scrollbar {
    display: none;
  }
.contingent-leader-modal-main-payment-buttons{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 10px; */
    gap: 10px;
    cursor: pointer;
}
.cont-leader-modal-cont-details{
   padding-top: 20px;
   padding-bottom: 20px;
   margin-top: 10px;
   margin-bottom: 10px;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap:10px;
    align-items: center;
    /* background: linear-gradient(rgba(248, 246, 246, 0.3), rgba(248, 246, 246, 0.3)); */
    /* background: linear-gradient(rgba(166, 45, 166, 0.3), rgba(166, 45, 166, 0.3));
    box-shadow: 0.5px 0.5px 10px #a928c9 inset ; */
    
}
.cont-leader-modal-member-details-head{
    font-size: 21px;
    margin-top: 10px;
    text-align:center;
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 2px solid white;
}

.cont-details{
    margin:10px 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.cont-details h3{
    -webkit-text-stroke: 1px #90e1ef36;
}
.cont-leader-modal-member-details{
    width:90%;
}

.cont-leader-modal-member-payment-status{
    display: flex;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    padding:20px 20px;
    margin-bottom: 15px;
    border-radius: 10px;
    backdrop-filter: blur(15px);
    box-shadow: 0px 4px 8px rgba(1, 1, 9, 0.721);
    /* background: linear-gradient( #90e1ef5f, #90e1efd0); */
    /* box-shadow: 0.5px 0.5px 10px #a928c9 inset ; */
   
}
.cont-leader-modal-deletemem-button{
    height: 40px;
    width: 140px;
    border-radius: 5px;
    border: none;
    background-color: red;
    color: white;
    cursor: pointer;
}
.cont-leader-modal-addmem-button{
    height: 40px;
    width: 100px;
    border-radius: 5px;
    border: none;
    background-color: green;
    color: white;
    cursor: pointer;
}
.cont-leader-modal-adddel-buttons{
    width: 90%;
    display: flex;
    justify-content:space-evenly;
    margin-top: 10px;
    margin: auto;
    cursor: pointer;
}
.cont-add-members-select{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
}
.cont-member-fields{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    border-radius: 10px;
    backdrop-filter: blur(15px);
    box-shadow: 0px 4px 8px rgba(1, 1, 9, 0.721);
}
.cont-addmem-head{
    margin-bottom: 10px;
    font-size: 20px;
}
.cont-addmem-sfid{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 90%;
    margin-bottom: 10px;
}
.cont-addmem-sfid input{
    width:60%;
    font-size: 16px;
    text-align: center;
    word-wrap:wrap;
    padding:8px;
    border:none;
    border-radius: 5px;
}
.cont-mem-modal{
    padding: 20px;
    background-color: white;
    display: flex;
    /* max-height: 400px; */
    border-radius: 20px;
    background-color: black;
    color:white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0.5px solid #501E5D;
    box-shadow: 7px 7px 17px #501E5D,
        -7px -7px 17px #501E5D;
    overflow:auto;
}
.cont-mem-details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-shadow: 0.5px 0.5px 20px #a928c9 inset ;
    border-radius: 10px;
    margin-bottom: 10px;
}
.cont-mem-leave{
    height: 40px;
    width: 140px;
    border-radius: 10px;
    border: none;
    background-color: red;
    color: white;
    box-shadow: 1px 1px 10px red;
}
.cont-leader-paid{
    color: green;
}
.cont-leader-unpaid{
    color: red;
}
.help-me{
    bottom: 0;
}
.contact-infos{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.help-container{
  border: 1px solid black;
  border-radius: 15px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  z-index: 100;
  color: white;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 90vw;
  max-height: 95vh; 
  height: 35vh; 
  margin: auto;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  position: relative;
}
/* .contact-infos{
    display: flex;
    flex-direction: column;
    gap: 15px;
} */
/* .cont-sub::before{
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: -1px;
    left: -1px;
    z-index: -10;
    background-color: transparent;
    padding: 2px;
} */
 
 .barcode-1{
    min-height:50vh;
    max-height: 65vh;
    
    background: rgba(1, 1, 9, 0.393); 
    border-radius: 10px;
    backdrop-filter: blur(12px);
    border:0.3px solid rgb(241, 241, 243);
    color:white;
    border-radius: 10px;
    overflow: auto;
    width:360px;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    position: relative;
 }

@media (max-width: 599px){
    .contingent-body{
        padding-top: 80px;
        /* padding-bottom: 100px; */
    }
}
@media (max-width: 370px){

    .contingent-form{
        width: 300px;
    }
    .cont-opt-bg{
        width: 150px;
    }
}
@media (max-width: 375px){
    
    .contingent_payment{
        width:300px;
    }
}
.contingent-payment-error{
    color: rgba(255, 0, 0, 0.978);
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
    backdrop-filter:blur(12px);
}
.contingent-payment-input-error{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
}

/* .cont-pay select{
    width: 135px;
    margin-left: 10px;
}

.cont-pay input{
    width: 135px;
    margin-left: 10px;
    position: relative;
} */

.cont-pay label{
    width:80px;
    position: relative;
}


@media (max-width:600px){
    .contingent-head{
        padding-top: 0px;
    }
    .contingent-leader-modal{
        width:90vw;
    }
}

.contingent-crown img{
    height:40px;

}

.cont-leader-paid p{
    color:green !important;
}

.contingent-leader-modal-main-payment-buttons{
    margin-bottom: 10px;
    cursor: pointer;
}

/* .cont-leader-modal-deletemem-button{
    margin-top: 10px;;
} */

.help-me{
    /* z-index:1000; */
    /* position: absolute; */
    font-size: 20px;
    padding:10px 20px;
    border-radius: 10px;
    background-color: wheat;
    position: relative;
    bottom:15px;
    cursor: pointer;
}
