.faq-style {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    background-image: url('../Events/assets/faqs_bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    padding-top: 0px;
    overflow: auto;
    position: fixed;
}

.faq-container {
    width: 50vw;
    height: 70vh;
    margin-top: 10px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    /* backdrop-filter: blur(15px); */
    border-radius: 15px;

    /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3); */
    overflow: auto;
    scrollbar-width: none;
    position: fixed;
}

.faq-title {
    margin-top:50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.faqs {
    border:none;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size:3em;
    color: rgb(0, 0, 0);
    margin-top: 10px;
    transition: all 0.3s ease;
    text-shadow: 0 0 10px #ffffff, 0 0 20px #f8f1f1, 0 0 30px #dbd6d6
}



.faq {
    background: rgba(34, 34, 35, 0.79);
    border-radius: 15px ;
    margin: 10px 0;
    padding: 10px;
    font-size:0.5em;
    text-align:center;
    cursor: pointer;
    transition: background-color 1s ease;
}

.faq.open {
    background: white;
}
.faq.open div{
    color: rgba(44, 100, 104, 0.753);
}

.faq-question {
    font-size: 1.2rem;
    font-weight: bold;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-answer {
    margin-top: 10px;
    font-size: 1rem;
    color: #ebe4e4;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.5s ease;
    opacity: 0;
}

.faq-answer.open {
    max-height: 200px;
    opacity: 1;
}

.faq-icon {
    margin-left: 10px;
    font-size: 1.2rem;
    /* animation: rotate 0.5s linear ; */
}

.faqs-socials-container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    /* position: fixed;
    bottom: 1.5rem; */
    visibility: hidden;
}

.faqs-socials-container a {
    font-size: 0;
}

.faqs-socials {
    height: 2rem;
    width: 2rem;
    border-radius: 20%;
    border: none;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    animation:  animateSocialsShadow 3s linear infinite;
    cursor: pointer;
}

#faqs-twitter-icon {
    background-image: url('../Homepage/Assests/xlogo.webp');
}

#faqs-fb-icon {
    background-image: url('../Homepage/Assests/fblogo.webp');
}

#faqs-insta-icon {
    background-image: url('../Homepage/Assests/insgtalogo.webp');
}

#faqs-yt-icon {
    background-image: url('../Homepage/Assests/ytlogo.webp');
}

#faqs-linkedin-icon {
    background-image: url('../Homepage/Assests/linkedinlogo.webp');
}



@media only screen and (max-width: 992px) {
    
    .faq-question {
        font-size: 1.1rem;
    }

    .faq-answer {
        font-size: 0.9rem;
    }
}


@media only screen and (max-width: 768px) {
    
    .faq-question {
        font-size: 1.1rem;
    }

    .faq-answer {
        font-size: 0.9rem;
    }

    .faq {
        padding: 15px;
    }
}

@media only screen and (max-width: 576px) {
    /* .faq-container {
        width: 90%;
        height: 90vh;
        overflow: auto;
        padding-top: 0px;
        margin: 0px;
        margin-top: 60px;

    }*/

    .faq-style {
        align-items: center;
        padding:0;
        margin-top: 0%;
        padding-top: 0%;
    }

    .faqs {
        width:80vw;
        margin: -10px;
        font-size:2.5em;
    } 
    .faq-container{
        width:100vw;
        height:70vh;
    }
    .faq-title{
        margin-top:0;
    }
    .faq-question {
        font-size: 0.9rem;
    }

    .faq-answer {
        font-size: 0.8rem;
    }

    .faq {
        padding: 15px;
        background: rgba(44, 100, 104, 0.753);
        backdrop-filter: blur(10px);
        
    }
}

@media only screen and (max-width:600px){
    .faqs-socials-container{
        visibility: visible;
    }
    .faq-container{
        margin-bottom: 30px;
        padding-top: 60px;
        min-height: 100vh;
        background-color: transparent;
        /* backdrop-filter: blur(15px); */
        
    }
    .faq-style{
        padding-bottom: 50px;
        overflow: auto;
        scrollbar-width: none;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-top: 0px;
    }
        .faqs-socials-container{
            margin-top: 20px;
            margin-bottom: 50px;
        }
}