@import url('https://fonts.googleapis.com/css2?family=Emilys+Candy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Emilys+Candy&family=Modern+Antiqua&display=swap');
.acc-bg{
    background-image: url("./Assets/House.webp");
    background-color: rgba(0, 0, 0, 0.178);
    background-blend-mode: multiply;
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 20px;
    z-index: -1;
}
.acc-bg img {
    display: none;;
}

.acc-bg img{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.acc-info{
   overflow: auto;
   height: 99.9vh;
   position: fixed;
   padding-bottom: 50px;
   width: 100%;
}
.acc-info::-webkit-scrollbar {
    display: none;
  }
.acctitdiv{
    display: flex;
    align-items: center;
    justify-content: center;
}

.acc-title{
    text-align: center;
    font-size: 2.5rem;
    margin: 0;
    margin-right: 105px;
    margin-top: 100px;
    color: #f5dae6;
  /* -webkit-text-stroke: 3px red; */
  position: relative;
    font-weight: bold;
    letter-spacing: 0px;
    font-family: "Modern Antiqua";
    /* text-shadow: 0 0 10px #ffffff, 0 0 20px #74b8dd, 0 0 30px #74b8dd; */
}

/* .acinfotit::before{
    content: "INFO";
}
.acruletit::before{
    content: "RULES";
}
.acfaqtit::before{
    content: "FAQ's";
}
.acmaptit::before{
    content: "REACHING IIT KGP";
} */
/* .acc-title::before{
    position: absolute;
    width: 0%;
    height: 100%;
    overflow: hidden;
    color: #765341;
    transition: 0.5s ease-in-out;
}

.acc-title:hover::before{
    border-right: 4px solid #765341;
    width: 100%;
    filter: drop-shadow(0 0 25px #765341);
  } */

.acc-side{
    z-index: 10;
    width: 0px;
    height: 100%; 
    position: fixed;
}

.acc-nav{
    margin: 0px 0px 0px 0px;
    height: 100vh;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.336);
    padding: 0 0 0 10px;
    display: flex;
    justify-content: center;
    list-style-type: none;
    flex-direction: column;
    gap: 50px;
    position: fixed;
    transition: all .5s;
    border-radius: 0px 0px 20px 20px;
    /* background-color: rgba(0, 0, 0, 0.342); */
    padding-right: 8px;
    border: 1px solid transparent ;
    /* background-image: linear-gradient(to bottom, rgba(135, 207, 235, 0.5) 0%, rgba(255, 192, 203, 0.788) 50%, rgba(135, 207, 235, 0.5) 100% ); */
    background-size: 100% 200%;
    /* animation: boxShadow 2s linear infinite; */
    /* , gradient-animation 3s infinite */
    ;
}

@keyframes gradient-animation {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 0% -200%;
    }
}
@keyframes boxShadow {
    0%{
        box-shadow: 0px 0px 20px #ff00e5;
    }
    50%{
        box-shadow: 0px 0px 2px #ff00e5;
    }
    100%{
        box-shadow: 0px 0px 20px #ff00e5;
    }
}


@keyframes move{
    from{
        background-position: 0px 0vh;
    }
    to{
        background-position: 0px 100vh;
    }
}

.actree{
    position: fixed;
    right: 40px;
    height: 100vh;
    width: 60px;
    z-index: 50;
}
.actm{ 
    position: fixed;
    right: 180px !important;
}

.acc-ind{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
}
.acc-ind > div:first-child{
    margin-bottom: 5px;
}

.acc-ind > div:last-child{
    font-size: 14px;
    font-weight: bold;
    color:white !important;
   /* -webkit-text-stroke: 1px black; */
   text-align: center;

}

.acc-nav a{
    text-decoration: none;
    color: white;
}
.acnavname{
    text-decoration: none;
    color: white;
}
.acimgbox{
    width: 35px;
    height: 35px;
    border-radius: 60%;
    background-color: wheat;
    margin-bottom: 2px !important;
    /* box-shadow: 0px 0px 20px black; */
}
.acimg{
    z-index: 2;
    /* position: relative; */
    border-radius: 50%;
    /* border: 2px solid white; */
}
.acimg2{
    width: 35px;
    z-index: 2;
}
@media (max-width: 500px){
    
    .offAccCls{
        position: fixed;
        right: -135px !important;
        padding-left: 5px !important;

    }
    .tiltcard{
        width: 90% !important; 
    }
}

.acc-ind a{
    font-size: large;
    font-weight: bold;
}
.newAccCls{
    position: fixed;
    right: 0px;
}
.offAccCls{
    position: fixed;
    right: 0px;
}

.acmapcont{
    /* background-image: url("./MapScroll.png"); */
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
    margin-right: 105px;
}
.acgmap{
    width: 80vw;
    height: 400px;
    /* filter: drop-shadow(1px 1px 10px gold); */
}

.acgmap iframe{
    height: 400px;
}

.acmapicont{
    padding: 10vw;
    position: relative;
}

.acmapcont img{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
}
.acgmap iframe{
    border-radius: 20px;
    width: 100%;
}

@media (max-width: 780px){
    .acc-nav{
        top: 70px;
        height: calc(100vh - 70px);
    }
}

@media (max-width: 599px){
    .acc-bg{
        background-image: url("./Assets/PhoneHouse.webp");
    }
    .acc-title{
        margin-top: 70px !important;
    }
    .acmapcont {
        margin-right: 0px!important;
    }
    .acimg2{
        width: 28px;
    }
    .acrot{
        height: 30px !important;
        width: 30px !important;  
        top: -1px !important;
    }
    .acc-nav{
        /* background-image: linear-gradient(to right, rgba(135, 207, 235, 0.5) 0%, rgba(255, 192, 203, 0.788) 50%, rgba(135, 207, 235, 0.5) 100% ); */
        position: fixed;
        top: 0;
        left: 0;
        right: auto;
        flex-direction: row;
        height: 65px;
        width: 100vw;
        justify-content: center;
        align-items: center;
        gap: 30px;
        /* animation: boxShadow 2s linear infinite; */
        /* , moveGradient 3s linear infinite */
        ;
        background-size: 200%;
    }
    .accontainer{
        margin-right: 0 !important;
    }
    .acc-title{
        margin-right: 0;
    }
    .acc-ind > div:last-child{
        text-align: center;
        font-size: 12px !important;
    }
    .acimg2{
    }
    .acgmap{
        width: 90vw;
    }
    .acimgbox{
        height: 28px;
        width: 28px;
    }
}

@keyframes moveGradient {
    0% {
      background-position: 0% 0%; /* Start position */
    }
    100% {
      background-position: 200% 0%; /* End position */
    }
}


@media (max-width: 480px){
    
    
    .acc-nav{
        /* background-image: linear-gradient(to right, blue 0%, pink 50%, blue 100%); */
        /* background-position: 0% 0; */
    }
    
    .acc-ind > div:last-child{
        font-size: 14px;
    }

    .accmid{
        width: 95vw !important;
    }
   
}

@media (max-width: 368px) {
    .acc-ind > div:last-child{
        font-size: 12px;
    }
    .acc-nav{
        gap: 15px;
    }
}

.acrot{
    /* content: "";
    position: absolute;
    height: 35px;
    width: 35x;
    z-index: 11;
    top: -2.5px;
    left: auto; */
    /* border-radius: 50%; */
    /* box-shadow: 0px 0px 20px wheat; */
    position: absolute;
    height: 39px;
    width: 39px;
    z-index: -1;
    top: -2px;
    border-radius: 50%;
    box-shadow: 0px 0px 15px wheat; 
    background: conic-gradient( transparent 60%, wheat);
    animation: rotate-gradient 2s linear infinite;
     background-color: black;
}
@keyframes rotate-gradient {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
  }


a, button {
    -webkit-tap-highlight-color: transparent !important;
    
    outline: none !important; 
}

.bluebtnfix{
    -webkit-tap-highlight-color: transparent !important;
    
    outline: none !important; 
}