
*{
    
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.forget{
    background: url('AlbedoBase_XL_create_an_image_of_dark_night_theme_of_a_carniva_0.webp') no-repeat center center fixed;
    background-size: cover;
    position: fixed;
    width: 100vw;
}

.forget-password-content {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    /* padding: 20px; */
    /* background: url('scroll3.webp') no-repeat; */
    background-position: center;
}
.forgetimg{
    position: absolute;
}

.forget-password-body {
    
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 430px;
    width: 100%;
   
}


.forget-password-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #0b0808;
    text-align: center;
    z-index: 10;
    position: relative;
}

.forget-password-form-cont {
    width: 100%;
    display: flex;
   justify-content: center;
   align-items: center
}

.forget-password-form-cont form {
    width: 85%;
}
.forget-password-inputfield {
    margin-bottom: 20px;
    position: relative;
   
}

.forget-password-inputfield input {
    width: 80%;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1.5px solid #0e0c0c;
    border-radius: 15px;
    font-size: 16px;
    outline: none;
    margin-left: 2em;

    transition: border-color 0.3s ease; 
    box-shadow: 0px 0px 8px rgb(194, 121, 3);
}


.forget-password-inputfield input:focus {
    border-color: #007bff;
}

.error-message {
    color: red;
    font-size: 12px;
    margin-top: 10px;
    position: absolute;
    display: flex;
    justify-content: end;
    bottom: -18px;
    width: 100%;
    margin-left: -1em;
}


.forget-password-btn-div {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.forget-password-button {
    background-color: #040403;
    color: #fff;
    padding: 10px 15px;
    border: none;
    width: 50%;
    border-radius: 12px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 10;
    position: relative;
   
}

.forget-password-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.forget-password-button:hover {
    background-color: #000000;
    
}


@media (max-width: 768px) {
    .forget-password-body {
        padding: 20px;
    }

    .forget-password-heading {
        font-size: 20px;
    }

    .forget-password-inputfield input {
        font-size: 14px;
    }

    .forget-password-button {
        font-size: 14px;
        padding: 8px 12px;
    }
}

@media (max-width:600px){

.forget{
    padding-bottom: 12vh;

}

}

@media (max-width: 520px) {
    .forgetimg{
        width: 95vw !important;
        height: 500px;
    }
}


@media (max-width: 480px) {
    
    .forget-password-body {
        padding: 15px;
        width: 70%;
    }

    .forget-password-heading {
        font-size: 18px;
    }

    .forget-password-inputfield input {
        width: 93%;
        font-size: 13px;
        padding: 8px 10px;
        margin-left: .8em;
    }

    .forget-password-button {
        font-size: 12px;
        padding: 7px 10px;
    }
}

.forgot-password-timer{
    position: relative;
    justify-self: center;
    margin-bottom:20px;
}

.authentication input:-webkit-autofill,
.authentication input:-webkit-autofill:hover,
.authentication input:-webkit-autofill:focus,
.authentication input:-webkit-autofill:active, .authentication input,.authentication select{
    transition: background-color 5000s ease-in-out 0s;
}

.authentication input:-webkit-autofill,
.authentication input:-webkit-autofill:hover, 
.authentication input:-webkit-autofill:focus, 
.authentication input:-webkit-autofill:active,
.authentication input,.authentication select{
    /* -webkit-background-clip: text; */
    -webkit-text-fill-color: black;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
    background-color: transparent;
    color: black;
}

