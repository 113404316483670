@import url('https://fonts.googleapis.com/css2?family=Agu+Display&family=Henny+Penny&family=Patua+One&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: "Agu Display", serif;
}



.headings-3 {
    margin-top: 7.5vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.eventheading {
    font-size: 3rem;
    /* Adjust size as needed */
    font-weight: bold;
    color: #ffffff;
    /* White color for contrast */
    text-align: center;
    text-transform: uppercase;
    /* Optional, makes it all caps */
    position: relative;
    /* z-index: 10; */
    text-shadow: 0 4px 6px rgba(0, 0, 0, 0.6);
    /* Subtle shadow for clarity */
    margin-top: 17px;
}

.glow-heading-event {
    font-size: 3rem;
    font-weight: bold;
    /* color: #ffffff; */
    text-align: center;
    /* text-shadow: 0 0 10px #ffffffc4, 0 0 20px #ff6b6bb6, 0 0 30px #ff6b6bb7; */
    text-shadow: 0 0 5px #ffffff80, 0 0 10px #ff6b6b80, 0 0 15px #ff6b6b80;
}

/* .register-event-form{
    display:flex;
    align-items: center;
    justify-content: center;    
} */

.subevents-body-container {
    background-image: url('../Events/assets/events_bg.jpg');
    /* background: linear-gradient(235deg, #FFFFFF 0%, #000F25 100%), linear-gradient(180deg, #6100FF 0%, #000000 100%), linear-gradient(235deg, #FFA3AC 0%, #FFA3AC 40%, #00043C calc(40% + 1px), #00043C 60%, #005D6C calc(60% + 1px), #005D6C 70%, #00C9B1 calc(70% + 1px), #00C9B1 100%), linear-gradient(125deg, #FFA3AC 0%, #FFA3AC 40%, #00043C calc(40% + 1px), #00043C 60%, #005D6C calc(60% + 1px), #005D6C 70%, #00C9B1 calc(70% + 1px), #00C9B1 100%);
    background-blend-mode: soft-light, screen, darken, normal; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    padding-top: 45px;
    overflow: auto;
    position: fixed;
}

.sub-events-buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0 2em;
    /* margin-top: 2vh; */
    height: 70vh;
}

.sub-events-button {
    cursor: pointer;
    height: 250px;
    width: 180px;
    margin: 10px;
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    /* background-color: rgba(41, 41, 64, 0.7); */
    border-radius: 10px;
    /* background: rgba(40, 25, 58, 0.8); */
    background-image: url(./subeventscard3.jpeg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 10px;
    color: #ffffff;
    /* White text */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
}

.sub-events-button:hover {
    background-color: hsl(240, 22%, 22%);
    transform: scale(1.05);
}

.subevents-container {
    background-color: rgba(1, 1, 9, 0.54);
    backdrop-filter: blur(10px);
    color: white;
    border-radius: 15px;
    padding: 25px;
    margin: 30px auto;
    margin-top: 60px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    position: relative;
    animation: fadeIn 0.3s ease-in-out;
    height: 70vh;
    overflow: auto;
    width: 70vw;

}

.subevents-heading-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.subevents-tharun {
    margin-left: 9px;
    width: 50%;
    text-wrap: wrap;
}

.subevents-container::-webkit-scrollbar {
    display: none;
}


.subevent-image {
    width: 100%;
    height: 40%;
}

.subevent-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: invert(1);
}

.sub-events-button h2 {
    color: black;
    height: 15%;
    text-align: center;
    margin-bottom: 20px;
}

.about-rules {
    margin: 0.4em;
    text-align: center;
}

.ReactModalPortal .ReactModal_Overlay.ReactModal_Overlay--after-open {
    background-color: unset !important;
}

.RegisterButton {
    background: rgba(255, 217, 0, 0.263);
    backdrop-filter: blur(3px);
    color: black;
    /* text-shadow: 1px 1px 10px black; */
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: 30px;
    cursor: pointer;
    border: 1px solid white;
    text-align: center;
}

.RegisterButton-1 {
    background: #4a4e69;
    color: #ffffff;
    border: solid 2px white;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    margin-right: 40px;
    width: 35%;
    font-size: 1.2em;
}

.RegisterButton-1:hover {
    background: #283593;
}

.ReactModal__Overlay {
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: transparent !important;
    backdrop-filter: blur(10px);
}

.Register_Modal__Content::-webkit-scrollbar {
    display: none;
}

.event-register-form h1 {
    margin-top: 15px;
}

.event-register-form button {
    margin-bottom: 15px;
}

.Register_Modal__Content {
    max-height: 70vh;
    background: rgba(1, 1, 9, 0.54);
    backdrop-filter: blur(12px);
    border-radius: 10px;
    width: 70vw;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    overflow: auto;

}


.event-about {

    background: rgba(255, 255, 255, 0.7);
    margin: 8px 0px;
    padding: 8px;
    color: black;
    border-radius: 5px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.event-rules {
    background: rgba(255, 255, 255, 0.7);
    margin: 8px 0px;
    padding: 8px;
    border-radius: 5px;
    color: black;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}


@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

.event-register-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:100%;
    width:100%;
}

.event-register-form::-webkit-scrollbar {
    display: none;
}

.subevents-body-container::-webkit-scrollbar {
    display: none;
}

.event-single-member {
    display: flex;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    margin: 8px 3px;
    padding-left: 3px;

}

.input-label label {
    width: 50%;
    text-wrap: wrap;

}

.Register_Modal__Content h1 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    text-align: center;
    color: white;
}

.Register_Modal__Content form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}


.Register_Modal__Content input,
.Register_Modal__Content select {
    padding: 7px 5px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 10px 5px;
}

.input-label {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    /* margin-right:10px; */
}


.Register_Modal__Content button[type="submit"] {
    background: #4a4e69;
    color: white;
    font-size: 1rem;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 50%;
}

.Register_Modal__Content button[type="submit"]:hover {
    background: #0056b3;
    
}


.Register_Modal__CloseButton {
    position: absolute;
    top: -5px;
    right: 10px;
    color: white;
    background: none;
    border: none;
    font-size: 2.5rem;
    cursor: pointer;
}

.Modal-closebuttun {
    color: white;
}

@media only screen and (max-width:447px) {
    .subevents-heading-container {
        flex-wrap: wrap;
        justify-content: space-evenly;

    }
}

@media only screen and (max-width: 730px) {
    .event-single-member {
        justify-content: center;
        flex-wrap: wrap;
    }

}

@media only screen and (max-width: 600px) {
    .headings-3 {
        margin-top: 0px;
        margin-bottom: -15px;
    }

    .subevents-body-container {
        padding-top: 0px;
        /* margin-top: -25px; */

    }

    .eventheading {
        margin: 0 0;
        font-size: 2em;
    }

    .subevents-container {
        width: 90vw;
        padding: 25px 10px;
    }

    .subevents-heading-container {
        flex-wrap: wrap;
    }

    .RegisterButton-1 {
        margin: 5px;
        font-size: 1em;
        width: auto;
        height: 100%;
        padding: 10px;

        text-align: center;
    }

    .RegisterButton {
        padding: 3px 5px;
    }

    .Register_Modal__CloseButton {
        top: -5px;
    }

    .Register_Modal__Content {
        background-size: cover;
        width: 90vw;
        margin-bottom: 100px;
    }

    .Register_Modal__Content h1 {
        font-size: 1.2em;
        text-wrap: auto;
    }

    .event-single-member {
        justify-content: center;
        flex-wrap: wrap;
    }

    .sub-events-buttons {
        margin: 3.5em 1em;
        overflow: auto;
        height: 65vh;
        padding-bottom: 10px;
    }

    .sub-events-button {
        width: 120px;
        height: 190px;
        margin: 5px;
    }

    .sub-events-button h2 {
        font-size: 1em;
    }
}