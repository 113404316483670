* {
  margin: 0;
  padding: 0;
  /* font-family: 'Cinzel-Decorative' !important;  */
}

@font-face {
  font-family: 'Cinzel-Decorative';
  /* A name to use for this font in your CSS */
  src: url('./Assests/Cinzel_Decorative/CinzelDecorative-Regular.ttf') format('truetype');
  /* Path to the .ttf file */
}

/* 
#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  background-position: center;
  min-width: 100%;
  min-height: 100%;
} */

#myVideo {
  z-index: -1;
}

.homepage-container {
  background: url('./Assests/bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  /* background-position: 50%; */
  height: 100vb;
  width: 100vw;
  overflow: hidden !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 0;
  position: fixed;
  /* overflow-y: hidden;
    height:100vh; */
}

.sflogo {
  background: url('./Assests/sf.png');
  background-size: contain;
  background-repeat: no-repeat;
  position: fixed;
  top: .5rem;
  width: 4.5rem;
  height: 4.5rem;
  left: 0.4rem;
  z-index: 1001;
}

.iitkgplogo {
  background: url('./Assests/iitkgp.png');
  background-size: contain;
  background-repeat: no-repeat;
  position: fixed;
  top: 1rem;
  right: 1rem;
  width: 3.4rem;
  height: 3.4rem;
  z-index: 1001;
}

.homepage-ilu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* z-index: 2; */
  position: fixed;
}

.homepage-ilu-img {
  margin-bottom: 1rem;
  /* height: 40vh; */
  width: 50vw;
}

.homepage-login-button {
  position: relative;
  height: 4rem;
  width: 8rem;
  color: #f7de9e;
  background-color: transparent;
  backdrop-filter: blur(5px);
  border-radius: 0.8rem;
  font-size: 1.8rem;
  /* animation: animateLoginButton 3s linear infinite; */
  animation: animateButtonShadow 5s linear infinite;
  cursor: pointer;
}

.dash-logout {
  /* width:100vw; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 20px;
  /* flex-direction: column; */
}

.homepage-dashboard-button {
  --black-700: rgb(56, 44, 72);
  --border_radius: 9999px;
  --transtion: 0.3s ease-in-out;
  --offset: 2px;
  margin-bottom: 20px;
  cursor: pointer;
  position: relative;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  transform-origin: center;

  padding: 1rem 2rem;
  background-color: transparent;

  border: none;
  border-radius: var(--border_radius);
  transform: scale(calc(1 + (var(--active, 0) * 0.1)));

  transition: transform var(--transtion);

}

.homepage-dashboard-button::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  height: 100%;
  background-color: var(--black-700);

  border-radius: var(--border_radius);
  box-shadow: inset 0 0.5px hsl(0, 0%, 100%), inset 0 -1px 2px 0 hsl(0, 0%, 0%),
    0px 4px 10px -4px hsla(0 0% 0% / calc(1 - var(--active, 0))),
    0 0 0 calc(var(--active, 0) * 0.375rem) hsl(260 97% 50% / 0.75);

  transition: all var(--transtion);
  z-index: 0;
}



.homi {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #c43aa8, #0a7de9);
  border: none;
  border-radius: 50px;
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 15px 30px;
  cursor: pointer;
  outline: none;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  text-transform: uppercase;
}

.homi:hover {
  transform: scale(1.05);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}

.homi span {
  z-index: 2;
}


.dots_border {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 80%;
  border: 3px dotted rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  animation: borderAnimation 2s linear infinite;
  transform: translate(-50%, -50%);
}


@keyframes borderAnimation {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.5;
  }

  50% {
    transform: translate(-50%, -50%) scale(1.1);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.5;
  }
}






.homepage-dashboard-button::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  height: 100%;
  background-color: hsla(260 97% 61% / 0.75);
  background-image: radial-gradient(at 51% 89%,
      hsla(266, 45%, 74%, 1) 0px,
      transparent 50%),
    radial-gradient(at 100% 100%, hsla(266, 36%, 60%, 1) 0px, transparent 50%),
    radial-gradient(at 22% 91%, hsla(266, 36%, 60%, 1) 0px, transparent 50%);
  background-position: top;

  opacity: var(--active, 0);
  border-radius: var(--border_radius);
  transition: opacity var(--transtion);
  z-index: 2;
}

.homepage-dashboard-button:is(:hover, :focus-visible) {
  --active: 1;
}

.homepage-dashboard-button:active {
  transform: scale(1);
}

.homepage-dashboard-button .dots_border {
  --size_border: calc(100% + 2px);

  overflow: hidden;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: var(--size_border);
  height: var(--size_border);
  background-color: transparent;

  border-radius: var(--border_radius);
  z-index: -10;
}

.homepage-dashboard-button .dots_border::before {
  content: "";
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: left;
  transform: rotate(0deg);

  width: 100%;
  height: 2rem;
  background-color: white;

  mask: linear-gradient(transparent 0%, white 120%);
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.homepage-dashboard-button .sparkle {
  position: relative;
  z-index: 10;

  width: 1.75rem;
}

.homepage-dashboard-button .sparkle .path {
  fill: currentColor;
  stroke: currentColor;

  transform-origin: center;

  color: hsl(0, 0%, 100%);
}

.homepage-dashboard-button:is(:hover, :focus) .sparkle .path {
  animation: path 1.5s linear 0.5s infinite;
}

.homepage-dashboard-button.sparkle .path:nth-child(1) {
  --scale_path_1: 1.2;
}

.homepage-dashboard-button .sparkle .path:nth-child(2) {
  --scale_path_2: 1.2;
}

.homepage-dashboard-button .sparkle .path:nth-child(3) {
  --scale_path_3: 1.2;
}

@keyframes path {

  0%,
  34%,
  71%,
  100% {
    transform: scale(1);
  }

  17% {
    transform: scale(var(--scale_path_1, 1));
  }

  49% {
    transform: scale(var(--scale_path_2, 1));
  }

  83% {
    transform: scale(var(--scale_path_3, 1));
  }
}

.text_button {
  text-align: center;
  text-wrap: nowrap;
}

.homepage-dashboard-button .text_button {
  position: relative;
  z-index: 10;

  background-image: linear-gradient(90deg,
      hsla(0 0% 100% / 1) 0%,
      hsla(0 0% 100% / var(--active, 0)) 120%);
  background-clip: text;

  font-size: 1rem;
  color: transparent;
}


.homepage-socials-container {
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
  position: fixed;
  bottom: 1.5rem;
}

.homepage-socials-container a {
  font-size: 0;
}

.pay-now-button{
  z-index: 1000;
}

.homepage-socials {
  height: 2rem;
  width: 2rem;
  border-radius: 20%;
  border: none;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  animation: animateSocials 3s ease-in-out 1 forwards, animateSocialsShadow 3s linear infinite;
  cursor: pointer;
}

#homepage-twitter-icon {
  background-image: url('./Assests/xlogo.webp');
}

#homepage-fb-icon {
  background-image: url('./Assests/fblogo.webp');
}

#homepage-insta-icon {
  background-image: url('./Assests/insgtalogo.webp');
}

#homepage-yt-icon {
  background-image: url('./Assests/ytlogo.webp');
}

#homepage-linkedin-icon {
  background-image: url('./Assests/linkedinlogo.webp');
}

.homepage-buttons {
  width: 40vw;
  max-width: 300px;
  padding-top: 0px;
  padding-bottom: 0px;
}

@keyframes animateButtonShadow {
  0% {
    box-shadow: 0px 0px 10px 10px #f7de9e;
  }

  25% {
    box-shadow: 0px 0px 10px 10px grey;
  }

  50% {
    box-shadow: 0px 0px 10px 10px #f7de9e;
  }

  75% {
    box-shadow: 0px 0px 10px 10px grey;
  }

  100% {
    box-shadow: 0px 0px 10px 10px #f7de9e;
  }
}

@keyframes animateSocialsShadow {
  0% {
    box-shadow: 0px 0px 3px 3px #f7de9e;
  }

  33% {
    box-shadow: 0px 0px 5px 5px white;
  }

  66% {
    box-shadow: 0px 0px 7px 7px #f7de9e;
  }

  100% {
    box-shadow: 0px 0px 5px 5px white;
  }
}

@keyframes animateSocials {
  0% {
    display: none;
    transform: rotate(0);
    bottom: -4rem;
  }

  10% {
    display: block;
  }

  30% {
    transform: rotate(360deg) translateX(1vw);
  }

  65% {
    transform: rotate(0deg) translateX(-1vw);
  }

  100% {
    transform: rotate(360deg);
    bottom: 0;
  }
}

@media screen and (max-width: 900px) {
  .homepage-ilu-img {
    width: 85vw;
    /* height: 45vh; */
  }
}

@media screen and (max-width: 750px) {
  .homepage-ilu-img {
    width: 95vw;
    /* height: 30vh; */
  }
}

@media screen and (max-width: 830px) and (min-width: 600px) {
  .iitkgplogo {
    top: 6.5rem;
  }

  .sflogo {
    top: 6.5rem;
  }
}

@media screen and (max-width: 600px) {
  .homepage-socials-container {
    display: none;
  }

  .iitkgplogo {
    left: 89vw;
  }

  .homepage-ilu-img {
    width: 80vw;
    /* height: 25vh; */
  }
}

@media screen and (max-width: 500px) {
  .iitkgplogo {
    left: 86vw;
  }

  .homepage-container {
    background: url('./Assests/phonehome.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: fixed;
  }

  .homepage-buttons {
    width: 200px;
  }

  .sparkles2 .text_button {
    justify-self: center;
  }
}

@media screen and (max-width: 400px) {
  .iitkgplogo {
    left: 82vw;
  }
}


@keyframes animateLoginButton {
  0% {
    border-top: 2px solid white;
    box-shadow: 0px -10px 10px #f7de9e;
  }

  25% {
    border-right: 3px solid white;
    box-shadow: 10px 0px 10px #f7de9e;
  }

  50% {
    border-bottom: 4px solid white;
    box-shadow: 0px 10px 10px #f7de9e;
  }

  75% {
    border-left: 5px solid white;
    box-shadow: -10px 0px 10px #f7de9e;
  }

  100% {
    border-top: 2px solid white;
    box-shadow: 0px -10px 10px #f7de9e;
  }
}



.sparkles {
  --clr: 260;
  --shadows: 0%;
  --shadowl: 0%;

  font-size: max(1.8vw, 1.25rem);
  font-weight: 700;
  letter-spacing: 0.5px;
  cursor: pointer;
  border-radius: 5em;
  background: linear-gradient(0deg,
      hsla(var(--clr), 100%, 70%) 0%,
      hsla(var(--clr), 100%, 65%) 5%,
      hsla(var(--clr), 80%, 35%) 15%,
      hsla(var(--clr), 10%, 0%) 40%,
      hsla(var(--clr), 25%, 12%) 90%);
  background-size: 200% 300%;
  background-position: 0% 0%;
  box-shadow: inset 0 0 2px hsla(var(--clr), 30%, 20%);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  place-items: center;
  padding: 0;
  position: relative;
  overflow: hidden;
  transform: translate(0px);

  transition: all 0.5s cubic-bezier(0.77, 0, 0.18, 1);

  box-shadow: 0 -0.5em 0.5em transparent, 0 0.5em 0.5em transparent, 0 0.5em 0.5em transparent, 0 0.5em 0.5em transparent,
    0 0.25em 0.3em -0.2em hsla(var(--clr), 0%, 0%, 0.5),
    0 0.35em 0.75em hsla(var(--clr), 0%, 0%, 0.75);
}

.sparkles::before,
.sparkles::after {
  --gradientPos: 50% 100%;
  content: " ";
  grid-column: 1;
  grid-row: 1;
  width: 100%;
  height: 100%;
  transition: inherit;
}

.sparkles:before {
  inset: 0;
  position: absolute;
  transform: translate3d(0, 0, 0.01px);
  border-radius: inherit;

  background-image: var(--glitter), var(--glitter),
    linear-gradient(180deg, black 0%, white 80%);
  background-size: 300px 170px, 280px 130px, 200% 200%;
  background-blend-mode: multiply, multiply, overlay;
  background-position: 0px 0px, 0px 0px, var(--gradientPos);
  background-repeat: repeat;

  mix-blend-mode: color-dodge;
  filter: brightness(2) contrast(.75);
  animation: bubble 20s linear infinite;
  animation-play-state: paused;
  opacity: 0.5;
  box-shadow: inset 0 -8px 10px -7px hsla(var(--clr), 70%, 80%, 0.75);
}

.sparkles:after {
  background-image: radial-gradient(ellipse at center 70%,
      hsla(var(--clr), 100%, 99%, 0.8) 5%,
      hsla(var(--clr), 90%, 80%, 1) 20%,
      transparent 50%,
      transparent 200%),
    linear-gradient(90deg,
      hsla(var(--clr), 80%, 10%, 1) -10%,
      transparent 25%,
      transparent 75%,
      hsla(var(--clr), 80%, 10%, 1) 110%);
  box-shadow: inset 0 0.25em 0.75em rgba(0, 0, 0, 1),
    inset 0 -0.05em 0.2em rgba(255, 255, 255, 0.4),
    inset 0 -1px 3px hsla(var(--clr), 80%, 50%, 0.75);
  background-blend-mode: darken;
  background-repeat: no-repeat;
  background-size: 180% 80%, cover;
  background-position: center 220%;
  mix-blend-mode: hard-light;
  filter: blur(5px);
  opacity: 0;
}

.sparkles,
.sparkles.over {
  --shadows: 90%;
  --shadowl: 80%;
  background-position: 100% 100%;

  transition: all 0.2s cubic-bezier(0.17, 0.84, 0.44, 1);

  box-shadow: 0 -0.2em 1.5em hsla(var(--clr), 90%, 50%, 0.3),
    0 0.5em 2em hsla(var(--clr), 90%, 70%, 0.55),
    0 0.25em 0.3em -0.2em hsla(var(--clr), 0%, 0%, 1),
    0 0.35em 0.75em hsla(var(--clr), 0%, 0%, 1),
    0 0.25em 0.5em -0.3em hsl(var(--clr), 30%, 99%, 1),
    0 0.25em 0.5em hsla(var(--clr), 20%, 30%, 0.35),
    inset 0 -2px 5px -2px rgba(255, 255, 255, 0.5);
}

.sparkles.over {
  transition-duration: 2s;
}

.sparkles:before,
.sparkles.over:before {
  --gradientPos: 50% 50%;
  animation-play-state: running;
  filter: brightness(2) contrast(1);
  box-shadow: inset 0 -5px 10px -4px hsla(var(--clr), 70%, 80%, 0.3);
  opacity: .8;
}

.sparkles:after,
.sparkles.over:after {
  opacity: .8;
  transform: translateY(0px);
}

.sparkles span {
  grid-column: 1;
  grid-row: 1;
  background-image: linear-gradient(hsl(calc(var(--clr) - 43), 27%, 85%) 0%,
      hsl(calc(var(--clr) - 60), 22%, 80%) 19%,
      hsl(calc(var(--clr) - 50), 20%, 75%) 30%,
      hsl(calc(var(--clr) - 52), 36%, 98%) 43%,
      hsl(var(--clr), 70%, 70%, 1) 51%,
      hsl(var(--clr), 50%, 85%, 1) 52%,
      rgb(255, 255, 255) 100%);
  background-size: 1em 3.45em;
  color: rgb(214, 222, 226);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  filter: drop-shadow(0 0 0.05em rgba(0, 0, 0, 0.5)) drop-shadow(0 0.05em 0.05em rgba(0, 0, 0, 0.5));
  transition-timing-function: inherit;
  transition-duration: inherit;
  transition-delay: 0s;
  padding: 0.75em 1.5em;
  transform: translateY(0);
  z-index: 10;
  /* width: 200px; */
}


/* 
.sparkles span,
.sparkles:active span,
.sparkles.over span {
  background-position-y: -100%;
} */

.sparkles:active {
  transform: translateY(0.075em);
  box-shadow: 0 -0.2em 1.5em hsla(var(--clr), 90%, 50%, 0.4),
    0 0.5em 2em hsla(var(--clr), 90%, 70%, 0.65),
    0 0.15em 0.3em -0.2em hsla(var(--clr), 0%, 0%, 1),
    0 0.25em 0.75em hsla(var(--clr), 0%, 0%, 1),
    0 0.25em 0.5em -0.3em hsl(var(--clr), 30%, 99%, 1),
    0 0.25em 0.5em hsla(var(--clr), 20%, 30%, 0.45),
    inset 0 -2px 5px -2px rgba(255, 255, 255, 0.65);
  transition-duration: 0.1s;
}

.sparkles:active:before,
.sparkles:active:after {
  opacity: 1;
  filter: brightness(3) contrast(.75);
  animation-duration: 8s;
}

.sparkles:active:after {
  filter: brightness(1.35) contrast(.8) blur(5px);
}

:root {
  --glitter: url("https://assets.codepen.io/13471/silver-glitter-background.png");
}



@keyframes bubble {
  0% {
    background-position: 0px 340px, 0px 130px, var(--gradientPos);
  }

  100% {
    background-position: 0px 0px, 0px 0px, var(--gradientPos);
  }
}

/* input {
  position: absolute;
  bottom: 30px;
  width: 300px;
} */

.sparkles2 {
  --clr: 260;
  --shadows: 0%;
  --shadowl: 0%;

  /* font-size: max(2.5vw, 1.25rem); */
  font-size: max(1.8vw, 1.25rem);

  font-weight: 700;
  letter-spacing: 0.5px;
  border-radius: 5em;
  background: linear-gradient(0deg,
      hsla(var(--clr), 100%, 70%) 0%,
      hsla(var(--clr), 100%, 65%) 5%,
      hsla(var(--clr), 80%, 35%) 15%,
      hsla(var(--clr), 10%, 0%) 40%,
      hsla(var(--clr), 25%, 12%) 90%);
  background-size: 200% 300%;
  background-position: 0% 0%;
  box-shadow: inset 0 0 2px hsla(var(--clr), 30%, 20%);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  place-items: center;
  padding: 0;
  position: relative;
  overflow: hidden;
  transform: translate(0px);

  transition: all 0.5s cubic-bezier(0.77, 0, 0.18, 1);

  box-shadow: 0 -0.5em 0.5em transparent, 0 0.5em 0.5em transparent, 0 0.5em 0.5em transparent, 0 0.5em 0.5em transparent,
    0 0.25em 0.3em -0.2em hsla(var(--clr), 0%, 0%, 0.5),
    0 0.35em 0.75em hsla(var(--clr), 0%, 0%, 0.75);
}

.sparkles2::before,
.sparkles2::after {
  --gradientPos: 50% 100%;
  content: " ";
  grid-column: 1;
  grid-row: 1;
  width: 100%;
  height: 100%;
  transition: inherit;
}

.sparkles2:before {
  inset: 0;
  position: absolute;
  transform: translate3d(0, 0, 0.01px);
  border-radius: inherit;

  background-image: var(--glitter), var(--glitter),
    linear-gradient(180deg, black 0%, white 80%);
  background-size: 300px 170px, 280px 130px, 200% 200%;
  background-blend-mode: multiply, multiply, overlay;
  background-position: 0px 0px, 0px 0px, var(--gradientPos);
  background-repeat: repeat;

  mix-blend-mode: color-dodge;
  filter: brightness(2) contrast(.75);
  animation: bubble 20s linear infinite;
  animation-play-state: paused;
  opacity: 0.5;
  box-shadow: inset 0 -8px 10px -7px hsla(var(--clr), 70%, 80%, 0.75);
}

.sparkles2:after {
  background-image: radial-gradient(ellipse at center 70%,
      hsla(var(--clr), 100%, 99%, 0.8) 5%,
      hsla(var(--clr), 90%, 80%, 1) 20%,
      transparent 50%,
      transparent 200%),
    linear-gradient(90deg,
      hsla(var(--clr), 80%, 10%, 1) -10%,
      transparent 25%,
      transparent 75%,
      hsla(var(--clr), 80%, 10%, 1) 110%);
  box-shadow: inset 0 0.25em 0.75em rgba(0, 0, 0, 1),
    inset 0 -0.05em 0.2em rgba(255, 255, 255, 0.4),
    inset 0 -1px 3px hsla(var(--clr), 80%, 50%, 0.75);
  background-blend-mode: darken;
  background-repeat: no-repeat;
  background-size: 180% 80%, cover;
  background-position: center 220%;
  mix-blend-mode: hard-light;
  filter: blur(5px);
  opacity: 0;
}

.sparkles2,
.sparkles2.over {
  --shadows: 90%;
  --shadowl: 80%;
  background-position: 100% 100%;

  transition: all 0.2s cubic-bezier(0.17, 0.84, 0.44, 1);

  box-shadow: 0 -0.2em 1.5em hsla(var(--clr), 90%, 50%, 0.3),
    0 0.5em 2em hsla(var(--clr), 90%, 70%, 0.55),
    0 0.25em 0.3em -0.2em hsla(var(--clr), 0%, 0%, 1),
    0 0.35em 0.75em hsla(var(--clr), 0%, 0%, 1),
    0 0.25em 0.5em -0.3em hsl(var(--clr), 30%, 99%, 1),
    0 0.25em 0.5em hsla(var(--clr), 20%, 30%, 0.35),
    inset 0 -2px 5px -2px rgba(255, 255, 255, 0.5);
}

.sparkles2.over {
  transition-duration: 2s;
}



.sparkles2:before,
.sparkles2.over:before {
  --gradientPos: 50% 50%;
  animation-play-state: running;
  filter: brightness(2) contrast(1);
  box-shadow: inset 0 -5px 10px -4px hsla(var(--clr), 70%, 80%, 0.3);
  opacity: .8;
}

.sparkles2:after,
.sparkles2.over:after {
  opacity: .8;
  transform: translateY(0px);
}

.sparkles2 span {
  grid-column: 1;
  grid-row: 1;
  background-image: linear-gradient(hsl(calc(var(--clr) - 43), 27%, 85%) 0%,
      hsl(calc(var(--clr) - 60), 22%, 80%) 19%,
      hsl(calc(var(--clr) - 50), 20%, 75%) 30%,
      hsl(calc(var(--clr) - 52), 36%, 98%) 43%,
      hsl(var(--clr), 70%, 70%, 1) 51%,
      hsl(var(--clr), 50%, 85%, 1) 52%,
      rgb(255, 255, 255) 100%);
  background-size: 1em 3.45em;
  color: rgb(214, 222, 226);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  filter: drop-shadow(0 0 0.05em rgba(0, 0, 0, 0.5)) drop-shadow(0 0.05em 0.05em rgba(0, 0, 0, 0.5));
  transition-timing-function: inherit;
  transition-duration: inherit;
  transition-delay: 0s;
  padding: 0.75em 1.5em;
  transform: translateY(0);
  z-index: 10;
  width: 200px;
}

/* 
.sparkles2: span,
.sparkles2:active span,
.sparkles2.over span {
  background-position-y: -100%;
} */

.sparkles2:active {
  transform: translateY(0.075em);
  box-shadow: 0 -0.2em 1.5em hsla(var(--clr), 90%, 50%, 0.4),
    0 0.5em 2em hsla(var(--clr), 90%, 70%, 0.65),
    0 0.15em 0.3em -0.2em hsla(var(--clr), 0%, 0%, 1),
    0 0.25em 0.75em hsla(var(--clr), 0%, 0%, 1),
    0 0.25em 0.5em -0.3em hsl(var(--clr), 30%, 99%, 1),
    0 0.25em 0.5em hsla(var(--clr), 20%, 30%, 0.45),
    inset 0 -2px 5px -2px rgba(255, 255, 255, 0.65);
  transition-duration: 0.1s;
}

.sparkles2:active:before,
.sparkles2:active:after {
  opacity: 1;
  filter: brightness(3) contrast(.75);
  animation-duration: 8s;
}

.sparkles2:active:after {
  filter: brightness(1.35) contrast(.8) blur(5px);
}

:root {
  --glitter: url("https://assets.codepen.io/13471/silver-glitter-background.png");
}

@keyframes bubble {
  0% {
    background-position: 0px 340px, 0px 130px, var(--gradientPos);
  }

  100% {
    background-position: 0px 0px, 0px 0px, var(--gradientPos);
  }
}

.sparkles2 {
  --clr: 120;
  /* Green hue */
  --shadows: 90%;
  --shadowl: 80%;
}

.homepage-buttons-conatiner {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  width: 100vw;
}

@media screen and (max-width:700px) {
  .homepage-buttons-conatiner {
    flex-direction: column;
    align-items: center;
    width: 100vw;
  }
}

.sparkles2 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.sparkles2 .text_button {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

link:focus,
link:active {
  outline: none;
  background: none;
}

@media screen and (max-height: 650px) {
  .homepage-ilu-container{
    position: relative;
    bottom: 5vh;
  }
  .homepage-ilu-img {
    margin-bottom: 0;
  }
}