* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.merch-body-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
}

.merchbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  background-image: url("merch_laptop.webp");
  background-size: 100vw 100vh;
  color: #fff;
  height: 100vh;
  padding: 1rem;
  overflow: hidden;
  box-sizing: border-box;
  padding-top: 20vh;
}

.merch-shirt-heading {
  margin-top: 4vh;
  font-size: xx-large;
  color: whitesmoke;
  font-weight: bold;
  text-shadow: 0 0 5px #ffffff80, 0 0 10px #ff6b6b80, 0 0 15px #ff6b6b80;
}

.merch-heading {
  font-size: 3rem;
  font-weight: bold;
  color: whitesmoke;
  text-shadow: 0 0 5px #ffffff80, 0 0 10px #ff6b6b80, 0 0 15px #ff6b6b80;
  margin-bottom: 1%;
  text-align: center;
}

.container {
  text-align: center;
  width: 90%;
  max-width: 37.5rem;
  height: auto;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 15vh;
}

.title {
  font-size: 1.8rem;
  margin: 1.25rem 0;
  text-shadow: 0 0 0.625rem rgba(255, 255, 255, 0.5);
}


.cart-icon {
  position: absolute;
  top: 20px;
  right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  z-index: 100;
}

.cart-icon .cart-count {
  position: relative;
  bottom: 180%;
  left: 40%;
  background-color: red;
  color: white;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}


.buttons {
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  gap: 0rem;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 1rem;
  padding: 5px;
  padding-top: 6px;
  padding-bottom: 6px;

}

.switch-btn {
  text-align: center;
  background: white;
  color: #fff;
  border: none;
  padding: 10px;
  margin: 0 0.625rem;
  font-size: 1rem;
  border-radius: 50%;
  cursor: pointer;
  animation: glowing1 2s linear 0s infinite normal;
  border: 1px solid black;
  border: none;
}

.switch-btn.active {
  background: rgb(168, 88, 243);
  border: none;
  border: 1px solid black;
  animation: glowing 2s linear 0s infinite normal;
}

@keyframes glowing {
  0% {
    box-shadow: 0px 0px 2px white;
  }

  50% {
    box-shadow: 0px 0px 15px white;
  }

  100% {
    box-shadow: 0px 0px 2px white;
  }
}

@keyframes glowing1 {
  0% {
    box-shadow: 0px 0px 2px rgb(168, 88, 243);
  }

  50% {
    box-shadow: 0px 0px 15px rgb(168, 88, 243);
  }

  100% {
    box-shadow: 0px 0px 2px rgb(168, 88, 243);
  }
}

.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 31.25rem;
}

.carousel img {
  transition: transform 0.5s linear, opacity 0.5s ease-in-out;
  filter: drop-shadow(0px 0px 10px whitesmoke);
  width: 60%;
  height: 40vh;
  border-radius: 0.625rem;
}

.carousel.slide-enter {
  transform: translateX(100vw);
  opacity: 0;
}

.carousel.slide-enter-active {
  transform: translateX(0);
  opacity: 1;
}

.carousel.slide-exit {
  transform: translateX(0);
  opacity: 1;
}

.carousel.slide-exit-active {
  transform: translateX(-100vw);
  opacity: 0;
}


.nav-btn {
  background: #A26EFF;
  color: #fff;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.3125rem 0.625rem;
  margin: 0 0.3125rem;
  border-radius: 5px;
}

.nav-btn:hover {
  background: #6b36c7;
}

.nav-btn-left {
  color: white;
  outline: none;
  border: none;
  z-index: 100;
  padding: 10px;
  background-color: #d600ff;
  border-radius: 10px;
  font-size: larger;
  cursor: pointer;
}

.nav-btn-right {
  color: white;
  border: none;
  outline: none;
  z-index: 100;
  padding: 10px;
  background-color: #d600ff;
  border-radius: 10px;
  font-size: larger;
  cursor: pointer;
}

.nav-btn-right:hover,
.nav-btn-left:hover {
  background: #b400d6;
}

.merch-image-carousel {
  position: relative;
  display: inline-block;
}

.merch-items-container {
  margin-top: 5vh;
  width: 98vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.merch-item {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 15px;
  width: 47.5vw;
  height: 48vh;
  padding: 20px;
  padding-bottom: 30px;
  background-color: rgba(166, 96, 230, 0.6);
  /* background-color: rgba(96, 179, 220, 0.7); */
  /* background-color: rgba(255, 111, 97, 0.8); */
}

.merch-photo {
  filter: drop-shadow(0px 0px 15px wheat);
  height: 30vh;
  width: 45vw;

}

.merch-price {
  margin-top: 2vh;
  font-size: x-large;
}

.merch-quan-checker {
  width: 38vw;
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.merch-check-btn {
  color: white;
  font-size: large;
  padding: 7px;
  border-radius: 7px;
  border: none;
  background-color: #98ff98;
  background-color: rgb(45, 185, 45);
}

.merch-details {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.carousel-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.carousel-btn-left {
  left: 10px;
}

.carousel-btn-right {
  right: 10px;
  color: white;
}

.buy-btn {
  transform: translateY(5vh);
  background: #FF6F61;
  color: #333333;
  border: none;
  padding: 0.5rem 1rem;
  font-size: larger;
  font-weight: 900;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 1rem;
}

.buy-btn:hover {
  background: #f16053;
}

.cart-icon {
  height: 3vh;
  position: absolute;
  top: 20px;
  right: 10px;
  font-size: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  color: #fff;
}

.crt {
  color: white;
}

.cart-icon .cart-count {
  position: relative;
  bottom: 180%;
  background-color: red;
  color: white;
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

@media (max-width: 850px) {
  .cart-icon {
    top: 7vh;
  }
}

.dots-container {
  text-align: center;
  margin-top: 10px;
}

.dot {
  transform: translateY(5vh);
  height: 12px;
  width: 12px;
  margin: 0 5px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.3s;
  cursor: pointer;
}

.dot.active {
  transform: translateY(5vh);
  transform: translateY(5vh) scale(1.4);
  background-color: #FF6F61;
}

@media (max-width: 600px) {

  .merch-body-container {
    position: fixed;
  }

  .merchbox {
    padding-top: 8vh;
    padding-bottom: 20vh;
    background-image: url("merch_phone.webp");
  }

  .carousel img {
    transform: scale(1.4) translateY(3vh);
    width: 90%;
    height: 30vh;
    object-fit: contain;
  }

  .merch-heading {
    margin-top: 10vh;
    font-size: 8vw;
  }

  .buttons {
    margin-top: 4vh;
  }

  .cart-icon {
    position: absolute;
    height: 3vh;
    top: 13px;
    right: 10px;
  }

  .cart-count {
    position: relative;
    bottom: 50%;
  }
}

@media (max-height: 700px) and (max-width: 600px) {
  .merch-heading {
    margin-top: 4vh;
  }
}

@media (min-width: 600px) {
  .carousel img {
    height: 50vh;
  }

  .dot,
  .dot.active {
    transform: translateY(-3vh);
  }

  .buy-btn {
    transform: translateY(-3vh);
  }
}

@media (min-width: 600px) {
  .merch-photo {
    width: 20vw;
  }
}

@media (min-width: 1000px) {
  .merch-heading {
    margin-top: 8vh;
  }

  .merch-photo {
    width: 15vw;
    height: 40vh;
  }

  .merch-item {
    width: 30vw;
    height: 55vh;
  }

  .merch-items-container {
    width: 70vw;
    justify-content: space-evenly;
  }

  .buttons {
    margin-top: 2vh;
  }
}