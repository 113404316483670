/* #root{
    overflow-y: hidden;
    height: 100vh;
} */
body{
    overflow: auto !important;
}

html{
    overflow: auto !important;
}

select {
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
}

select{
    background-image:
    linear-gradient(45deg, transparent 50%, black 50%),
    linear-gradient(135deg, black 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}


select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
/* 
select.minimal:focus {
  background-image:
    linear-gradient(45deg, green 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, green 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 15px) 1em,
    calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
  border-color: green;
  outline: 0;
} */



.dashboard {
    min-height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: black;
    background-image: url(./webp_images/dashboard_background.webp);
    mix-blend-mode: multiply;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment:fixed;
    position:fixed;
    /* overflow: hidden; */
    /* background: linear-gradient( rgba(0, 0, 0, 0.5) ); */
    /* z-index: 2; */
    font-family: sans-serif;
    position: fixed;
}

.dashboard-phone-top {
    /* height: 10vh; */
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2000;
    padding-top: 10px;

}

.dashboard-back {
    height: 37px;
    width: 37px;
    border-radius: 50%;
    background-image: url(arrow_back_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.png);
    background-size: cover;
    background-color: black;
    margin-left: 10px;
    visibility: hidden;
}
.dashboard-logout{
    margin-right: 10px;
   
    height: 40px;
    width: 100px;
    color: white;
    font-weight: 700;
    font-size: 20px;
    background: #1c1f2b;
    text-align: center;
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

/* .dashboard-logout {
    height: 40px;
    width: 100px;
    color: white;
    font-weight: 700;
    font-size: 20px;
    filter: drop-shadow(1px 1px 10px white);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    margin-right: 10px;
    cursor: pointer;
    border: 1px solid white;
    background-color: black;
    z-index: 1100;
    margin-left: 45%;
} */

.dashboard-logout::after,.dashboard-logout::before{
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    /* background-image: conic-gradient(from var(--angle), #ff4545, #00ff99, #006aff, #ff0095, #ff4545); */
    background-image: conic-gradient(from var(--angle), #006aff);
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    z-index: -1;
    padding: 3px;
    border-radius: 10px;
    animation: 2s spin linear infinite;
}
@property --angle{
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
}
@keyframes spin{
    from{
      --angle: 0deg;
    }
    to{
      --angle: 360deg;
    }
}
.dashboard-logout::before{
    filter: blur(1.5rem);
    opacity: 0.5;
  }

.dashboard-mid {
    /* height: 40vh; making dp crooked */
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.dashboard-dp {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background-color: transparent;
    filter: drop-shadow(1px 1px 10px white);
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;
    justify-content: center;
    align-items: center;
    animation: float-dashboard-dp 6s ease-in-out infinite;
    cursor: pointer;
}

.dashboard-dp-male {
    background-image: url(./webp_images/Dashboard_dp_Male.webp);
}

.dashboard-dp-female {
    background-image: url(./webp_images/femaleicon.webp);
}

@keyframes float-dashboard-dp {
    0% {
        box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
        transform: translatey(0px);
    }

    50% {
        box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
        transform: translatey(-20px);
    }

    100% {
        box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
        transform: translatey(0px);
    }
}

.dashboard-username {
    height: 50px;
    width: 100%;
    color:#E0FFFF;
    text-shadow: 0px 0px 10px black,
        0px 0px 10px black,
        0px 0px 10px black,
        0px 0px 10px black;
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 35px;
    /* padding-top: 7px; */
}

.dashboard-id {
    height: 50px;
    width: 100%;
    font-weight: 900;
    color: #E0FFFF;
    text-shadow: 0px 0px 10px black,
        0px 0px 10px black,
        0px 0px 10px black,
        0px 0px 10px black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    padding-top: 2.5px;

}


.dashboard-background-blur {
    width: 0;
    height: 0;
    backdrop-filter: blur(7.5px);
    position: absolute;
}

.dashboard-small-icons {
    display: flex;
    flex-direction: row;
    align-items: start;
    font-weight: 900;
    font-size: 1.15rem;
    text-align: center;
    justify-content: space-evenly;
    list-style: none;
    width: 100vw;
    color: #E0FFFF;
    text-shadow: 0px 0px 5px black,
        0px 0px 5px black,
        0px 0px 5px black,
        0px 0px 5px black;
    /* margin-bottom: 10px; */

}

.dashboard-small-icons li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1.4rem;
    /* width:33.33%; */
}

.dashboard-profile-icon {
    animation: animateSmallIcons 4s ease-in-out infinite;
    background-image: url(./webp_images/crystal_ball.webp);
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-register-icon {
    animation: animateSmallIcons 4s ease-in-out infinite;
    background-image: url(./webp_images/crystal_ball.webp);
}

.dashboard-complaint-icon {
    animation: animateSmallIcons 4s ease-in-out infinite;
    background-image: url(./webp_images/crystal_ball.webp);

}

.dashboard-add-team-member-button{
    cursor: pointer;
    padding: 10px;
    border-radius: 0.5rem;
    background-color: #f0b445;
    margin-bottom: 15px;
    margin-top:20px;
}

.dashboard-add-team-member-button:hover{
    background-color: yellow;
}

.dashboard-small-icons li div:first-child {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    /* background-image: url(./crystal_ball.png); */
    background-size: 100% 100%;
}

/* .dashboard-register-icon div{
    width:50%;
    height:50%;
    border-radius: 50%;
    background-image:url('RegisteredEvents.png') ;
    z-index: 1;
} */

.dashboard-register-image{
    width:10px;
    height:10px;
    /* background-image: url(dashboardscroll1.png); */
    background-size:cover;
    
}



.dashboard-small-icons li div:last-child {
    text-align: center;
    /* width: 75px; */
   
}
.dashboard-register-text{
    /* margin-top: 0.5rem; */
    width: 150px;
    /* margin-right: 20px; */
}
.dashboard-profile-text{
    /* margin-top: 0.5rem; */
}
.dashboard-complain-text{
    /* margin-top: 0.5rem; */
    width: 128px;
    /* text-wrap:wrap;
    margin-left: 20px;; */
}

.dashboard-large-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    width: 90vw;
    align-self: center;
    text-shadow: 0px 0px 10px white,
        0px 0px 10px white,
        0px 0px 10px white,
        0px 0px 10px white;
    /* margin-left: 0px; */
    padding-left: 0px;

}

.dashboard-large-icons li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1.4rem;
}

.dashboard-pay div:first-child {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-image: url(./webp_images/crystal_ball.webp);
    background-size: 100% 100%;
}

.dashboard-pay-icon:first-child{
    animation: animateSmallIcons 4s ease-in-out infinite;
}

.dashboard-pay-icon:last-child{
    animation: none;
    text-shadow: none;
    color:#CCCCCC;
}


.dashboard-complaint-icon {
    /* background-image: url(dashboardcomp1.png); */
    background-size: 50%;
}

.close-icon-img{
    position: relative;
    left: 24rem;
    top:0.6rem;
    width: 30px;
    height: 30px;
    background-image: url(./closeicon.png);
    background-size: 100% 100%;
    background-color: transparent;
    cursor: pointer;
    border-radius: 10%;
    border: none;

}

.dashboard-profile-icon div {
    /* background-image: url(dashboardprofile2.png); */
    height: 25%;
    width: 25%;
}

.dashboard-scan-icon {
    background-image: url(./webp_images/crystal_ball.webp);
    background-size: cover;
    display: flex;
    border-radius: 50%;
    flex-direction: column;
    align-items: center;
    animation: animateSmallIcons 4s ease-in-out infinite;

}

.dashboard-pay-icon {
    background-image: url(./webp_images/crystal_ball.webp);
}

.dashboard-large-icons li div:last-child {
    text-align: center;
    width: 100px;
}

.dashboard-modal-closingicon {
    margin-top: 5px;
    position: absolute;
    right: 40px;
    scale:0.8;
}


.scan {

    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    scale: 0.5;
}

.scan .qrcode {
    position: relative;
    width: 100px;
    height: 100px;
    /* border-radius: 50%; */
    background: url(dashboardscanqr_code1.webp);
    background-size: cover;
    background-repeat: no-repeat;
}

.scan .qrcode::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: url(dashboardscanqr_code2.webp);
    background-size: 100px;
    overflow: hidden;
    background-size: cover;
    animation: animate 4s ease-in-out infinite;
}

@keyframes animate {

    0%,
    100% {
        height: 20px;
    }

    50% {
        height: calc(100% - 20px);
    }
}

.scan .qrcode::after {

    content: '';

    position: absolute;

    inset: 20px;

    width: calc(100% - 40px);

    height: 2px;

    background: #35fd5c;

    background-size: cover;
    background-repeat: no-repeat;

    filter: drop-shadow(0 0 20px #35fd5c) drop-shadow(0 0 60px #35fd5c);

    animation: animateLine 4s ease-in-out infinite;

}

@keyframes animateLine {

    0% {
        top: 20px;
    }

    50% {
        top: calc(100% - 20px);
    }
}

.scan h3 {

    color: yellow;
    /* letter-spacing: 2px; */
    /* margin-top: 30px; */
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 800;
    filter: drop-shadow(0 0 20px black) drop-shadow(0 0 60px black);
    animation: animateText 2s steps(1) infinite;
}

@keyframes animateText {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

@keyframes animateSmallIcons {
    0% {
        box-shadow: 0px 0px 5px 3px white;
    }

    25% {
        box-shadow: 0px 0px 10px 5px white;
    }

    50% {
        box-shadow: 0px 0px 15px 7px white;
    }

    75% {
        box-shadow: 0px 0px 10px 5px white;
    }

    100% {
        box-shadow: 0px 0px 5px 3px white;
    }
}

.border {

    position: absolute;
    inset: 0;
    background: url(dashboardscanqr.webp);
    background-size: 100px;
    background-repeat: no-repeat;
    animation: animateText 1s linear infinite;
    /* scale: ; */

}


/*---------------- modals------------------------------ */

.dashboard-modals {
    width: 100%;
    max-width: 600px;
    align-self: center;
    /* height:450px; */
    /* padding-left: 10px;
    padding-right: 10px; */
    background-color: transparent;
    /* position: absolute; */
    /* top: 15vh; */
    z-index: 100;
    display: none;
    flex-direction: column;
    align-items: center;
    /* color: #F8C471; */
    /* color: #CCCCCC; Pure white for the main title */
    /* text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8); */
    color:#CCCCCC;
    max-height: 90vh;
    min-height: 30vh;
    height: fit-content;
    /* margin-bottom: 9vh; */

}

.dashboard-modal-container{
    width: 100%;
    max-width: 600px;
    position: absolute;
    top:15vh;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height:80vh;
    display: none;
    /* margin-top: 0; */

}

.dashboard-modal-head {
    cursor: pointer;
    padding-top: 15px;
    padding-bottom: 15px;
}

.dashboard-modal-form {
    /* margin-left: 30px;
    margin-right: 30px; */
    width: 90%;
    /* background-color: red; */
    /* padding: 10px; */
    /* background-color: black; */
    
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5));
    background-image: url(./modal-background-cropped.png);
    background-blend-mode: darken;
    /* opacity: 0.5; */

    background-size:cover;
    background-repeat: repeat;
    display: flex;
    background-position: center;
    flex-direction: column;
    align-items: center;
    
    /* padding-bottom: 20px; */
    border-radius: 3px 30px;
    padding-top: 15px;
    border: solid 2px #CCCCCC;
    overflow-y: auto;
    padding-bottom: 15px;
    /* padding-top: 0px; */

}

.dashboard-modal-form div{
    opacity: 1;
}

.dashboard-modal-form::-webkit-scrollbar {
    display: none;
    /* Hides the scrollbar */
}

.dashboard-modal-head {
    height: 70px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    background-image: url(modal-background.webp);
    background-size:cover;
    width: 90%;
    border-radius: 2px 15px;
    margin-bottom: 15px;
    border: solid 2px #CCCCCC;
}





.dashboard-modal-form-subhead {
    /* height:50px; */
    margin-bottom: 15px;
    padding-bottom: 10px;
    font-size: 20px;
    border-bottom: 2px solid #CCCCCC;
    width: 90%;
    text-align: center;
    text-shadow: 1px 1px 5px black;
}


/* ---------------------------------------profile modal -----------------------*/

.dashboard-profile-modal-field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 90%;
    height: 45px;
    font-size: 16px;
}

.dashboard-profile-modal-field div {
    display: flex;
    justify-content: center;
    align-items: center;
}



.dashboard-profile-modal-value {
    overflow: hidden;
    text-overflow: ellipsis;
    /* padding-left: 1px;
    margin-left: 3px; */
    /* white-space: nowrap; */
    max-width: 70%;
    
}



/*------------------register modal----------------------*/
.dashboard-register-modal-field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;


    width: 90%;
    height: fit-content;
    font-size: 15px;
    margin-bottom: 10px;
}



.dashboard-register-modal .dashboard-modal-form:last-child {
    margin-top: 15px;

}

.dashboard-register-modal .dashboard-modal-form {
    max-height: 40vh;
  
}

.dashboard-register-submodal {
    width: 90%;
   
}

.dashboard-register-submodal .dashboard-register-modal-field{
    margin-right: 9%;
}

.dashboard-register-modal-unregister {
    background-color:#CCCCCC;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3px 5px;
    border-radius: 7px;
    height: 30px;
    text-align: center;
    position: relative;
    /* border:2px solid rgb(248, 178, 48) */
    cursor: pointer;
}

.dashboard-deregister-button {
    left: 10%;
}
.dashboard-deregister-team {
    left: 22%;
    background-color: #CCCCCC;;
    padding-top:15px;
    padding-bottom: 15px;
    line-height: 13px;

}

/* .dashboard-deregister-team-leader{
    left: 17%;
    background-color: wheat;
}

.dashboard-deregister-team-member{
    background-color: #d3b0b0;
    left: 17%;
}

.dashboard-deregister-team-member:hover{
    background-color: #d3b0b0;
} */

.dashboard-deregister-member{
    left: 20%;
    background-color: #CCCCCC;;
    padding:15px 5px;
    font-size: 12px;
    line-height: 13px;
}

/* .dashboard-deregister-member-member {
    background-color: #d3b0b0;
    left: 20%;
}

.dashboard-deregister-member-leader {
    left: 20%;
    background-color: wheat;
} */

#dashboard-register-modal-unregister {
    font-weight: 700;
    font-size: 13px;
    width:95%;
}

.dashboard-register-submodal {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dashboard-register-modal-unregister:hover {
    background-color: #D4E6F1;
}

.dashboard-register-modal-event {
    display: flex;
    flex-direction: column;
    justify-items: center;
    /* padding-top: 5px;
    padding-bottom: 5px; */
    overflow: hidden;
    line-height: 16px;
    text-overflow: ellipsis;
    width:75%;
    height: 50px;
    margin-bottom: 20px;


   
}

#dashboard-register-modal-groupmember {
    width: 80%;
    font-size: 13px;
}
.dashboard-add-team-member-modal{
    background-color: black;
    color: #CCCCCC;;
    /* margin: 0.5rem; */
    /* position: relative; */
    /* width: 28rem; */
    width: 100%;
    padding: 0.2rem 1rem;
    /* top: -10vh; */
    /* right: 0vw; */
    left: -4vw;
    border-radius:  30px;
    border: 2px solid #CCCCCC;;
    z-index: 1;
    background-image:url(modal-background.webp) ;
    background-position: center;
    background-size: cover;

    /* margin-bottom: 1rem; */
}

.dashboard-add-team-member-modal input{
    padding: 0.2rem;
    margin: 0.5rem ;
    width:65%;
}

.dashboard-add-team-member-submit-button{
    cursor: pointer;
    padding: 0.3rem;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
}
#dashboard-register-modal-sfId{
    margin-left: 10px;
}
.dashboard-add-team-member-button{
    border:none;
    /* width: 0px; */
    background-color: #CCCCCC;;
}
.dashboard-add-team-member-submit-button{
    border:none;
    width: 100px;
    background-color: #CCCCCC;;
}
.dashboard-add-team-member-button:hover{
    background-color: #D4E6F1;
}
.dashboard-add-team-member-submit-button:hover{
    background-color: #D4E6F1;
}
.dashboard-add-team-member-input{
    border: none;
    border-radius: 5px;
    height: 30px;
    background-color: rgb(245, 229, 199);
}
.dashboard-add-team-member-field{
    margin-top: 10px;
}
.dashboard-modal-form{
    z-index: 1000000;
}
.dashboard-register-submodal{
    z-index: 1000000;
}
.dashboard-add-team-member-form{
    width:100%;
    display: flex;
   flex-direction: column;
   justify-content: center;
   align-items:center ;
}
.dashboard-register-image{
    height:90px;
    width: 90px;
    filter: drop-shadow(10px 10px 10px black)
}
.dashboard-profile-image{
    height: 90px;
    width: 90px;
    filter: drop-shadow(10px 10px 10px black)
}
.dashboard-complaint-image{
    height: 90px;
    width: 90px;
    filter: drop-shadow(10px 10px 10px black)
}

/*------------------Report Issue Modal----------------------*/

.dashboard-modal-comlaint-form-group {
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.dashboard-modal-formconatiner {
    width: 90%;

}

.dashboard-modal-compliant-form-control {
    margin-top: 20px;
    margin-bottom: 20px;
    height: 50px;
    font-size: 17px;
    color: black;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 30px;
    background-color: rgb(251, 238, 214);

}

/* .dashboard-modal-compliant-form-group{
    align-items: center;
    display: flex;
    justify-content: center;
    
} */
.dashboard-modal-complaint-submit-button {
    height: 50px;
    width: 30%;
    margin-bottom: 20px;
    cursor: pointer;
}

#dashboard-modal-complaint-submit-button {
    margin: auto;
    margin-bottom: 20px;
    font-size: 20px;
    border-radius: 10px;
    background-color: #CCCCCC;;

}

#dashboard-modal-form-subhead-submit-complaint {
    margin: auto;
    margin-bottom: 15px;
    cursor: pointer;
}

.dashboard-modal-compliant-form-control::-webkit-scrollbar {
    display: none;
}

.dashboard-modal-compliant-form-control {
    max-width: 95vw;
}
.dashboard-complaint-status-modal{
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: auto;

}
.dashboard-complaint-cancel{
    border: none;
    /* height: 20px;
    width: 70px; */
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 5px;
    background-color:#CCCCCC;;
    cursor: pointer;
    /* position: absolute;
    right:45px; */

}
.dashboard-complaint-cancel:hover{
    background-color: #D4E6F1;
}
.dashboard-compliant-id{
    font-size: 18px;
    font-weight: 600;
}
.dashboard-complaint-id-cancel{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: 17px;
}
.dashboard-complaint-issue-status{
    margin-bottom: 5px;
    
}
.dashboard-complaint-issue-status{
    margin-left: 15px;
    margin-top: 5px;
    /* text-wrap: wrap; */
}
#dashboard-complaint-issuetype{
   width:90%;
   word-wrap: break-word;
  

}

@media screen and (min-width:600px) {
    .border {

        background-size: 100px;
        scale: 1.25;
        /* position: relative; */
        left: 15px;
        top: 15px;
        /* left:10px; */
        /* scale: ; */

    }

    .dashboard-large-icons {
        position: absolute;
        top: 30vh;
        justify-content: space-between;
        /* margin-left: 40vw;
        margin-right: 40vw; */
        width: 95vw;
        max-width: 800px;
    }

    .dashboard-mid {
        margin-top: 13vh;
    }

    .dashboard-small-icons {
        /* margin-top: 7vh; */
        justify-content: center;
    }

    .dashboard-profile {
        margin-left: 80px;
        margin-right: 80px;
    }

    .dashboard-small-icons li div:first-child {
        width: 100px;
        height: 100px;
    }

    .dashboard-large-icons li div:first-child {
        width: 130px;
        height: 130px;
    }

    .dashboard-dp {
        /* width:200px;
        height: 200px; */
        scale: 1.2;
    }

    .dashboard-username {
        margin-top: 15px;
    }

    
}

@media screen and ((min-width:600px) and (max-width:1100px)) {
    .dashboard-phone-top {
        margin-top: 100px;
        justify-content: center;

    }

    /* .dashboard-login{
        justify-self: center;
    } */
}

.dashboard-modal-head{
    height: 70px;
    margin-bottom: 15px;
}

@media screen and (max-width:600px){
    .dashboard-modal-container{
        bottom:20vh;

        height:85vh;
       
    
    }

    .dashboard-phone-top{
        /* height: 6vh; */
        /* change when schedule is attached */
    }

    .dashboard-small-icons{
        margin-top:1vh;
    }

    /* .dashboard-modals{
       position: absolute;
       top:15vh;

    } */

    .dashboard-modals{
        margin-bottom: 10vh;
        max-height: 85vh;
    }

    .dashboard-modal-closingicon {
        margin-top: 5px;
        margin-right: 15px;
        position: absolute;
        right: 20px;
        scale:0.7;
    }
    .dashboard-register-image{
        height:75px;
        width: 75px;
        filter: drop-shadow(10px 10px 10px black)
    }
    .dashboard-profile-image{
        height:75px;
        width: 75px;
        filter: drop-shadow(10px 10px 10px black)
    }
    .dashboard-complaint-image{
        height:75px;
        width: 75px;
        filter: drop-shadow(10px 10px 10px black)
    }


    .close-icon-img{
        /* right:50vw; */
        top:3%;
        left: 14rem;
        /* position: absolute; */
    }
}
@media screen and (max-width:400px){
    .close-icon-img{
        /* right:50vw; */
        top:3%;
        left: 12rem;
        /* position: absolute; */
    }
}

.dashboard-complaint-subfield{
    /* background-color: red; */
    margin-top: 2vh;
    margin-bottom: 2vh;
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)); 
    padding: 20px;
    border-radius:10px ;
}

select{
    cursor: pointer;
    width:90%;
}

.dashboard-register-submodal{
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5));
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
    padding: 20px;
    border-radius:10px ;
}

strong{
    font-size: 17px;
    font-weight:600;
}

.dashboard-complaint-issuetype{
    font-weight: 500;
}

.dashboard-modal-compliant-form-control{
    /* padding-right: 100px; */
    background-color: #CCCCCC;
    width: 100%;
    padding-left: 20px;
    text-align: left;
}




/* .dashboard-register-submodal :first-child :first-child :first-child{
    font-size: 18px;
    margin-bottom: 2vh;
   
} */

/* .dashboard-register-submodal :first-child :first-child :last-child{
   
    padding:17px 10px;
    margin-bottom: 2vh;

} */

.dashboard input{
    background-color: #CCCCCC;;
}

.dashboard-register-modal-field-title{
    font-size: 18px !important;
    
}

.dashboard-crown img{
    height:25px;
    
}

.dashboard-crown{
    position: relative;
    bottom: 4px;
}

.dashboard-pay-text,.dashboard-scan-text{
    color:#E0FFFF;
    /* text-shadow: none; */
    text-shadow: 0px 0px 5px black,
    0px 0px 5px black,
    0px 0px 5px black,
    0px 0px 5px black;
    
    /* margin-top: 8px; */
}
.dashboard-scan-text{
    /* word-wrap: none; */
    /* width:fit-content !important; */
    position: relative;
    bottom: 11px;
}

.dashboard-schedule-hourglass{
    height: 80%;
}

.dashboard-pay-icon{
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-scan-icon{
    scale:0.75;
}

.dashboard-profile-modal-field div{
    /* text-align:right; */
    display: block;
}

.dashboard-register-modal-field-title{
    line-height: 20px;
    height:fit-content;


}
.dashboard-register-modal-field div {
 height:fit-content
}

.dashboard-register-modal-event{
    margin-bottom:0px;
}
.barcode-1 {
    min-height: 50vh;
    max-height: 65vh;
    background: rgba(1, 1, 9, 0.393);
    border-radius: 10px;
    backdrop-filter: blur(12px);
    border: 0.3px solid rgb(241, 241, 243);
    color: white;
    border-radius: 10px;
    overflow: auto;
    width: 360px;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    position: relative;
}

@media screen and (max-height:500px) {
    .dashboard-profile-data{
        padding-bottom: 10vh !important;
    }
}