@import url('https://fonts.googleapis.com/css2?family=Modern+Antiqua&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.home-page-bg {
    background-image: url('./Assests/image.webp');
    position: fixed;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.games-div {
    display: flex;
    gap: 4em;
    justify-content: center;
    align-items: center;
}

.game-heading {
    display: flex;
    justify-content: center;
    font-size: 5em;
    font-family: "Modern Antiqua", serif;
    color: rgb(185, 42, 195);
    align-items: center;
    text-align: center;
    font-weight: 700;
    margin: 2rem 0;
}
.game-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: wheat;
    font-family: "Modern Antiqua", serif;
    text-align: center;
    /* gap: 1em; */
}

.game-box {
    width: 250px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: #e5e4e4;
    border: 2px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    font-size: 18px;
    font-weight: bold;
    color: #fffaff;
    text-align: center;
    text-shadow: black;
    font-family: "Modern Antiqua", serif;
    box-shadow: 7px 7px 10px rgb(54, 27, 64);
}

.game-box:hover {
    transform: scale(1.1);
    transition-duration: 1s;
    background-color: rgba(123, 47, 190, 0.3);
}

.game-box button {
    background-color: #8b2394;
    color: #ffffff;
    border: none;
    padding: 10px 15px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 15px;
    transition: background-color 0.3s;
}

.game-box button:hover {
    background-color: #cc2dda;
}

.game1 {
    background-image: url('./Assests//game1.webp');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 1em;
}

.game2 {
    background-image: url('./Assests//game2.1.webp');
    background-position: top;

    background-size: cover;

    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 1em;

}

@media (max-width: 768px) {

    .game-heading {
        font-size: 4em;
        text-align: center;
    }

    .game-content {
        overflow: auto;
    }

    .games-div {
        flex-direction: column;
        gap: 2em;
        /* padding-bottom: 7em; */
    }
    .game-title{
        padding-bottom: -1em;
    }

    .game-box {
        width: 220px;
        height: 280px;
    }
}


@media screen and (max-width: 480px) {
    .home-page-bg {
        background-position: center;
        overflow: hidden;
    }

    .game-content {
        overflow: auto;
        padding-bottom: 22vh;
    }

    .game-heading {
        font-size: 3em;
        text-align: center;
    }

    .games-div {
        gap: 2em;
        /* padding-bottom: 9em; */
    }

    .game-box {
        width: 220px;
        height: 280px;
        font-size: 14px;
    }
}