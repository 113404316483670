* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.headings-2{
    margin-top: 7.5vh;
}
a{
    text-decoration: none;
}
.events{
    position: fixed;
    padding-top:15vh;
    background-image:url('./assets/events_bg.jpg') ;
    /* background: linear-gradient(235deg, #FFFFFF 0%, #000F25 100%), linear-gradient(180deg, #6100FF 0%, #000000 100%), linear-gradient(235deg, #FFA3AC 0%, #FFA3AC 40%, #00043C calc(40% + 1px), #00043C 60%, #005D6C calc(60% + 1px), #005D6C 70%, #00C9B1 calc(70% + 1px), #00C9B1 100%), linear-gradient(125deg, #FFA3AC 0%, #FFA3AC 40%, #00043C calc(40% + 1px), #00043C 60%, #005D6C calc(60% + 1px), #005D6C 70%, #00C9B1 calc(70% + 1px), #00C9B1 100%);
    background-blend-mode: soft-light, screen, darken, normal; */
    transition: all 500ms;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    background-position: center;
    background-attachment: fixed;
    border: 1px solid black;
    height: 50vh;
    overflow: auto;
}
.events::-webkit-scrollbar {
    display: none;
  }
.Event-body {
    /* height: 70vh;
    overflow: auto; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 70px;

}
.Event-body::-webkit-scrollbar {
    display: none;
  }
.event-heading-name {
    position: relative;
    border-radius: 7px; 
    text-align: center; 
    color: #f2de6b;
    /* overflow: hidden;  */
    background: rgba(255, 217, 0, 0.409);
    color: #ffffff; 
    box-shadow: 0px 4px 15px rgba(255, 217, 0, 0.885);
  }
 
.eventName{
    color:black;
    z-index: 1;
}
.hexagon {
    width: 200px;
    height: 240px; 
     margin: 0 100px;
    /* clip-path: polygon(
        50% 0%,   
        100% 10%, 
        100% 90%, 
        50% 100%, 
        0% 90%,   
        0% 10%  
    ); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* border: 2px solid black; */
    margin:18px;
    /* background-image: url('./assets/DALL·E\ 2025-01-03\ 19.06.26\ -\ A\ set\ of\ elegant\ hexagonal\ cards\ with\ plain\ interiors\ in\ shades\ of\ purple\ and\ dark\ blue\,\ designed\ with\ intricate\ golden\ borders.\ The\ borders\ feature\ d\ \(1\).jpg'); */
    /* background-color: rgba(2, 6, 43, 0.5); */
    /* background-image: url('./assets/card.png'); */

    /* backdrop-filter:blur(15px); */
    background-position: center ;
    background-size:cover;
    position: relative;
   
    /* -webkit-box-shadow: 0 0 50px rgb(97, 155, 206);
        -moz-box-shadow: 0 0 50px rgb(97, 155, 206);
        box-shadow: 0 0 50px rgb(97, 155, 206); */
    transition: all 500ms;
} 
.hexagonBackground{
    position: absolute;
    /* padding: 0 40px; */
    width: 190px;
    height: 220px;
    /* filter: drop-shadow(0px 0px 10px gold); */
    filter: drop-shadow(0px 4px 6px #d4af37);

}
.hexagon:hover{
    transform: scale(1.05);
}
.literary{
    margin-top: -1px;
}
.event-img{
    width: 75px; 
    height: 87px;
    margin-bottom: 15px;
    filter:invert(0);
    text-shadow: 1px 1px 5px rgba(255, 215, 0, 0.7);
}
.dance{
    margin-top: -2px;
}
.event-img-1{
    width: 65px; 
    height: 100px;
    margin-bottom: 4px;
    filter:invert(0);
}
.event-img-2{
    width: 87px; 
    height: 75px;
    margin-bottom: 20px;
    filter:invert(0);
}
.event-img-3{
    width: 80px; 
    height: 95px;
    margin-bottom: 0px;
    filter:invert(0);
}
.eventheading{
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .events{
        padding: 5vh 0;
    
    }
    .Event-body{
        padding: 0;
        padding-bottom: 100px;
    }
    .eventheading{
        text-align: center;
        font-size: 35px;
    }
    .hexagon {
        width: 140px;
        height: 180px;
        /* background-image: url('./assets/cardpink (1).jpeg'); */
        background-position: center ;
        background-size: fill;
    }
    .hexagonBackground{
        width: 140px;
        height: 180px;
    }
    .event-img{
        width: 70px; 
        height: 80px;
        margin-bottom: 10px;
    }
    .eventName{
        font-size: 13px;
        /* backdrop-filter: blur(15px); */
    }
    
    .event-img-1{
        width: 47px; 
        height: 80px;
        margin-bottom: 8px;
    }
    .fashion{
        margin-top: 1px;
    }
    .humourfest{
        margin-top: -3px;
    }
    .event-img-2{
        width: 90px; 
        height: 70px;
        margin-bottom: 15px;
    }
    .drama{
        margin-top: -3px;
        margin-bottom: 5px;
    }
    .music{
        margin-top: 3px;
    }
    .event-img-3{
        width: 80px; 
        height: 99px;
        margin-bottom: 0px;
    }
}
