.merch-container {
  border: 1px solid black;
  border-radius: 15px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  z-index: 100;
  color: white;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 90vw;
  max-height: 95vh; 
  height: auto; 
  margin: auto;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  position: relative;
}

.Register_Modal__CloseButton {
  position: absolute;
  top: 10px; 
  right: 10px; 
  font-size: 2rem;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10;
}

.Register_Modal__CloseButton:hover {
  color: red;
}


.merchbodycontainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.merch-heading-container {
  text-align: center;
  margin-bottom: 20px;
  
}



.merch-cost {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  font-size: 1.5em;
  gap: 10px;
}

.size-selection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  width: 100%;
}

.size-selection h3 {
  margin: 0 0 10px;
}

.sizes {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 4%;
}

.size-btn {
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 8px 12px;
  background: #f9f9f9;
  cursor: pointer;
  transition: background 0.3s, border-color 0.3s;
}

.merch-image-carousel img{
  filter: drop-shadow(0px 0px 10px white);
  width: 70vh;
  height: 80vh;
}

.size-btn.selected {
  border-color: rgb(137, 137, 226);
  background: rgb(121, 226, 240);
  color: black;
}

.product-description {
  margin: 20px 0;
}

.product-description p {
  font-size: 1em;
  line-height: 1.5;
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}
.merch-image-container {
  width: 78vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
}

.merch-image-nav {
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
}

.merch-image-nav.left {
  color: white;
  left: 10px;
}

.merch-image-nav.right {
  color: white;
  right: 10px;
}

.merch-image-dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.merch-dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: gray;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.merch-dot.active {
  background-color: #98ff98;
  transform: scale(1.4);
}

.add-to-cart,
.buy-now {
  width: 40vw;
  background: #2ecc71;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background 0.3s;
  display: flex;
  justify-content: space-between;
}

.add-to-cart:hover,
.buy-now:hover {
  background: #27ae60;
}
@media (min-width: 600px) and (max-width: 1000px) {
  .add-to-cart {
    width: 17vw;
  }
}
@media (min-width: 1000px) {
  .add-to-cart {
    width: 10vw;
  }
}
@media (max-width: 768px) {
  
  .merch-container {
    flex-direction: column;
    padding: 15px;
    max-height: 95vh;
  }

  .merchbodycontainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

.merch-image-carousel img{
  height: 60vh;
  margin-top: -8%;
  width: 60vh;

}

  

  .merch-cost {
    font-size: 1.2em;
  }

  .action-buttons {
    flex-direction: column;
    gap: 10px;
  }
  .add-to-cart{
    margin: 0 auto;
  }
}
@media (max-width: 768px) and (min-width: 600px) {
  .merch-container {
    margin-top: 12vh;
  }
}
@media (max-width: 600px) {
  
  .merchandise-det {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .merch-container {
    margin-top: 7vh;
  }
  .size-selection {
    align-items: center;
  }
}
@media (max-width: 480px) {
  .merch-container {
    padding: 10px;
  }

  

  .merch-cost {
    font-size: 1em;
  }

  .action-buttons {
    padding: 0;
  }

  .size-btn {
    padding: 6px 10px;
    font-size: 0.9em;
  }

  .add-to-cart,
  .buy-now {
    padding: 8px 16px;
    font-size: 0.9em;
  }
}
.merch-quantity {
  font-size: larger;
  width: 15vw;
  color: black;
  background-color: white;
  background-image: none;
  border: none;
  outline: none;
  text-align: center;
  -webkit-appearance: auto;
  appearance: auto;
}

@media (min-width: 850px) {
  .merch-photo {
    height: 38vh;
  }
  .merch-container {
    margin-top: 13vh;
    width: 50vw;
  }
  .merch-quantity {
    width: 3vw;
  }
  .merch-quan-checker {
    width: 11vw;
  }
}
@media (min-width: 600px) and (max-width: 850px) {
  .merch-container {
    margin-top: 20vh;
  }
}
@media (min-height: 1000px) {
  .merch-container {
    margin-top: 20vh;
    width: 90vw;
  }
  .merch-photo {
    width: 35vw;
  }
  .merch-price {
    font-size: xx-large;
  }
  .size-selection {
    font-size: xx-large;
  }
  .size-btn {
    font-size: x-large;
  }
  .merch-details h2 {
    font-size: xx-large;
  }
  .merch-quan-checker {
    width: 25vw;
  }
  .merch-quantity {
    width: 7vw;
  }
  .add-to-cart {
    font-size: xx-large;
  }
}

