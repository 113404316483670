@import url('https://fonts.googleapis.com/css2?family=Kurale&display=swap');


.gallery-container {
  background: url('background_2.webp');
  width: 100vw;
  height: 100vh;
  background-size: 100vw 100vh;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
  font-family: "Kurale", serif;
  font-weight: 400;
  font-style: normal;
}

.gallery-slide {
  width: 60vw;
  height: 70vh;
  position: absolute;
  left: 20vw;
  top: 27vh;
  border-radius: 2vw;
}

.gallery-items {
  width: 15vw;
  height: 30vh;
  background-position: center;
  display: inline-block;
  transition: 0.5s ease-in-out;
  background-size: cover;
  position: absolute;
  top: 30%;
  border-radius: 20px;
  box-shadow: 0 30px 50px #505050;
}

.gallery-items:nth-child(1) {
  top: 0;
  left: 0;
  z-index: 2; /* Back */
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
  border-radius: 2vw;
  box-shadow: 0px 0px 20px rgba(255, 193, 111, 0.5);
}

.gallery-items:nth-child(2) {
  top: 0;
  left: 0;
  z-index: 3; /* Front */
  width: 100%;
  height: 100%;
  border-radius: 2vw;
  box-shadow: 0px 0px 20px rgba(255, 193, 111, 0.5);
}

.gallery-items:nth-child(3) {
  left: 90%;
  z-index: 4;
}

.gallery-items:nth-child(4) {
  left: calc(90% + 16vw);
  z-index: 4;
}

.gallery-items:nth-child(n+5) {
  left: calc(90% + 32vw);
  opacity: 0;
}

.gallery-items.zoomed {
  z-index: 5;
  transform: scale(1.05);
  transition: transform 0.4s ease, z-index 0.4s ease;
}

.gallery-heading {
  position: absolute;
  top: 15vh;
  left: 41vw;
  font-weight: bolder;
  font-size: 4vw;
  color: white;
  text-shadow: 0 0 5px #ffffff80, 0 0 10px #ff6b6b80, 0 0 15px #ff6b6b80;
}

.gallery-prev-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 5vw;
  top: 57%;
  border: none;
  border-radius: 50%;
  width: 4vw;
  background-color: wheat;
  outline: none;
  cursor: pointer;
  z-index: 4;
  object-fit: contain;
}
.gallery-next-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 5vw;
  top: 57%;
  border: none;
  border-radius: 50%;
  width: 4vw;
  background-color: wheat;
  outline: none;
  cursor: pointer;
  z-index: 4;
  object-fit: contain;
}

.gallery-prev-button:hover, .gallery-next-button:hover {
  box-shadow: 0px 0px 20px wheat;
}

.gallery-close-zoom {
  position: absolute;
  top: 2vh;
  right: 5vw;
  font-size: 3vw;
  width: 5vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  background: rgba(0, 0, 0, 1);
  border-radius: 50%;
  font-weight: 900;
}
.gallery-close-zoom:hover {
  box-shadow: 0px 0px 20px wheat;
}



@media (max-width: 1000px) and (min-width: 600px) {
  .gallery-heading {
    font-size: 7vw;
    left: 38vw;
  }
  .gallery-slide {
    top: 30vh;
    width: 76vw;
    height: 50vh;
    left: 12vw;
  }
  .gallery-items {
    top: 35%;
    width: 25vw;
    height: 20vh;
  }
  .gallery-prev-button {
    right: 0.5vw;
    top: 50%;
    width: 8vw;
  }
  .gallery-items:nth-child(4) {
    left: calc(90% + 25vw);
    z-index: 4;
  }
}



@keyframes buttonAnimation {
  0% {
    right: -10vw;
    transform: rotate(-1440deg);
  }
  100% {
    right: 1vw;
    transform: rotate(0deg)
  }
}




/* Phone view */

/* Gallery.css */

/* Base styles */
/* Gallery.css */
.galleryPhone-container {
  touch-action: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  background-image: url("./background_3.webp");
  background-size: cover;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 1500px; 
}

.galleryPhone-card {
  position: absolute;
  top: 25vh;
  width: 60vw;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s ease, opacity 0.5s ease, z-index 0.5s ease;
  transform-style: preserve-3d;
}
.galleryPhone-slider {
  position: absolute;
  top: 10vh;
  width: 100vw;
  height: 80vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.galleryPhone-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
  box-shadow: 0 1vh 2vh rgba(0, 0, 0, 0.3);
}

.galleryPhone-heading {
  text-shadow: 0 0 5px #ffffff80, 0 0 10px #ff6b6b80, 0 0 15px #ff6b6b80;
  font-size: 8vw;
  position: absolute;
  top: 1vh;
  left: 34vw;
  color: white;
  z-index: 4;
}

@media (max-width: 450px) {
  .galleryPhone-card {
    top: 30vh;
    width: 70vw;
    height: 40vh;
  }

  .galleryPhone-heading {
    font-size: 10vw;
    left: 29vw;
  }
}
@media (min-height: 600px) {
  .galleryPhone-card {
    height: 25vh;
    top: 29vh;
  }
}








