*{
    margin: 0;
    padding: 0;
}

.leaderboard-main-container{
    background: url(./Assests/bcg.webp) no-repeat center center/cover;
    height: 100vh;
    color: #C0C0C0;
    position: fixed;
    width: 100vw;
}

.all-data-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.all-players{
    width: 85%;
    padding: 10px 2vw;
    background-color: transparent;
    backdrop-filter: blur(10px);
    border: 2px solid #C0C0C0;
    border-radius: 5px;
    max-height: 50vh;
    overflow: hidden;
    overflow-y: scroll;
}

.leaderboard-container h1 {
    padding: 2rem;
    text-align: center;
}

.leader-cols, .player-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem;
    margin: 0 auto;
    width: 85%;
    max-width: 1000px;
}

.player-card {
    background-color: #C0C0C0;
    color: black;
    border-radius: 5px;
    border-bottom: 5px solid rgba(0, 0, 139, 0.812);
}

.leader-cols h3, .player-details {
    flex: 1;
    text-align: center;
    word-wrap: break-word;
}

.leaderboard-user-container {
    display: flex;
    width: 100vw;
    justify-content: center;
    z-index: 2;
    padding: 10px 0;
}

.leaderboard-user {
    background-color: rgba(1, 1, 9, 0.614);
    color: #C0C0C0;
    position: fixed;
    bottom: 7vh;
    backdrop-filter: blur(10px);
    border-radius: 5px;
    padding: 1rem 0;
    width: 80%;
    display: flex;
}

.play-game{
    position: fixed;
    bottom: 18vh;
}

.play-btn{
    background-color: #C0C0C0;
    font-size: 1.5rem;
    border: none;
    border-bottom: 3px solid rgba(0, 0, 139, 0.812);
    border-right: 3px solid rgba(0, 0, 139, 0.812);
    border-radius: 0.5rem;
    padding: 0.5rem;
    cursor: pointer;
}
