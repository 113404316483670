* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.new-preloader-container {
    height: 100vh;
    width: 100vw;
    background-color: black;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    position: fixed;
}

.press-enter-text {
    color: white;
    margin-top: 1rem;
}

.preloading-ilu-img {
    width: 45vw;
}

.loading-dots {
    display: inline-block;
    font-size: 1.2em;
    animation: dots 2.4s infinite;
    opacity: 0;
}

.loading-dots:nth-child(1) {
    animation-delay: 0.4s;
}

.loading-dots:nth-child(2) {
    animation-delay: 0.8s;
}

.loading-dots:nth-child(3) {
    animation-delay: 1.2s;
}

@keyframes dots {
    30% {
        -webkit-transform: translateX(5px);
        transform: translateX(5px);
        opacity: 1;
        text-shadow: 0 15px 10px black;
    }

    48% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 0;
    }
}





.progress {
    background: rgba(255, 255, 255, 0.1);
    justify-content: flex-start;
    border-radius: 100px;
    align-items: center;
    position: relative;
    padding: 0 5px;
    display: flex;
    height: 40px;
    width: 30vw;
    margin: 2rem 0;
}

.progress-value {
    animation: load 3.5s normal forwards;
    box-shadow: 0 10px 40px -10px #fff;
    border-radius: 100px;
    background: #fff;
    height: 30px;
    width: 0;
}

.enter-button {
    background-color: transparent;
    border: 2px solid white;
    color: white;
    padding: 0.5rem;
    font-size: 1.25rem;
    border-radius: 1rem;
    cursor: pointer;
}

@media screen and (max-width: 500px) {
    .preloading-ilu-img {
        width: 90vw;
    }

    .press-enter-text {
        font-size: 0.9rem;
    }
}

@keyframes load {
    0% {
        width: 0;
    }

    50% {
        width: 40%;
    }

    80% {
        width: 70%;
    }

    95% {
        width: 90%;
    }

    100% {
        width: 100%;
    }
}




















.preloader-container {
    height: 100vh;
    width: 100vw;
    background-color: black;
    z-index: 10000;
}

.preloader-body {
    width: 100vw;
    height: 100vh;
    position: relative;
    background-color: black;

    /* The filter gives the crazy effect - cool shii */
    filter: blur(10px) contrast(10);
    will-change: contents;
}

.circle1 {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    background-color: white;

    /* For chromatic Aberration  */
    box-shadow: 0px 0px 10px red, 0px 0px 20px blue, 0px 0px 21px green;

    /* The Gibberish numbers are bezier curve for bounce easing */
    animation: grow 4s linear(0 0%,
            0 2.27%,
            0.02 4.53%,
            0.04 6.8%,
            0.06 9.07%,
            0.1 11.33%,
            0.14 13.6%,
            0.25 18.15%,
            0.39 22.7%,
            0.56 27.25%,
            0.77 31.8%,
            1 36.35%,
            0.89 40.9%,
            0.85 43.18%,
            0.81 45.45%,
            0.79 47.72%,
            0.77 50%,
            0.75 52.27%,
            0.75 54.55%,
            0.75 56.82%,
            0.77 59.1%,
            0.79 61.38%,
            0.81 63.65%,
            0.85 65.93%,
            0.89 68.2%,
            1 72.7%,
            0.97 74.98%,
            0.95 77.25%,
            0.94 79.53%,
            0.94 81.8%,
            0.94 84.08%,
            0.95 86.35%,
            0.97 88.63%,
            1 90.9%,
            0.99 93.18%,
            0.98 95.45%,
            0.99 97.73%,
            1 100%) infinite alternate,
        rot 4s ease 1s infinite;
    will-change: contents;
}

.circle2 {
    width: 180px;
    height: 180px;
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    /* border-radius: 50%; */
    background-color: rgb(0, 0, 0);

    /* For chromatic Aberration  */
    /* box-shadow:
0px 0px 10px red,
0px 0px 20px blue,
0px 0px 21px green; */

    /* The Gibberish numbers are bezier curve for bounce easing */
    animation: grow 4s linear(0 0%,
            0 2.27%,
            0.02 4.53%,
            0.04 6.8%,
            0.06 9.07%,
            0.1 11.33%,
            0.14 13.6%,
            0.25 18.15%,
            0.39 22.7%,
            0.56 27.25%,
            0.77 31.8%,
            1 36.35%,
            0.89 40.9%,
            0.85 43.18%,
            0.81 45.45%,
            0.79 47.72%,
            0.77 50%,
            0.75 52.27%,
            0.75 54.55%,
            0.75 56.82%,
            0.77 59.1%,
            0.79 61.38%,
            0.81 63.65%,
            0.85 65.93%,
            0.89 68.2%,
            1 72.7%,
            0.97 74.98%,
            0.95 77.25%,
            0.94 79.53%,
            0.94 81.8%,
            0.94 84.08%,
            0.95 86.35%,
            0.97 88.63%,
            1 90.9%,
            0.99 93.18%,
            0.98 95.45%,
            0.99 97.73%,
            1 100%) infinite alternate,
        rot 3s ease 2s infinite;
    will-change: contents;
}

.circle3 {
    width: 160px;
    height: 160px;
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    /* For chromatic Aberration  */
    box-shadow: 0px 0px 10px red, 0px 0px 20px blue, 0px 0px 21px green;

    /* The Gibberish numbers are bezier curve for bounce easing */
    animation: grow 4s linear(0 0%,
            0 2.27%,
            0.02 4.53%,
            0.04 6.8%,
            0.06 9.07%,
            0.1 11.33%,
            0.14 13.6%,
            0.25 18.15%,
            0.39 22.7%,
            0.56 27.25%,
            0.77 31.8%,
            1 36.35%,
            0.89 40.9%,
            0.85 43.18%,
            0.81 45.45%,
            0.79 47.72%,
            0.77 50%,
            0.75 52.27%,
            0.75 54.55%,
            0.75 56.82%,
            0.77 59.1%,
            0.79 61.38%,
            0.81 63.65%,
            0.85 65.93%,
            0.89 68.2%,
            1 72.7%,
            0.97 74.98%,
            0.95 77.25%,
            0.94 79.53%,
            0.94 81.8%,
            0.94 84.08%,
            0.95 86.35%,
            0.97 88.63%,
            1 90.9%,
            0.99 93.18%,
            0.98 95.45%,
            0.99 97.73%,
            1 100%) infinite alternate,
        rot 2s ease 3s infinite;
    will-change: contents;
}

.circle4 {
    width: 130px;
    height: 130px;
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    /* border-radius: 50%; */
    background-color: rgb(0, 0, 0);
    /* For chromatic Aberration  */
    /* box-shadow:
0px 0px 10px red,
0px 0px 20px blue,
0px 0px 21px green; */

    /* The Gibberish numbers are bezier curve for bounce easing */
    animation: grow 4s linear(0 0%,
            0 2.27%,
            0.02 4.53%,
            0.04 6.8%,
            0.06 9.07%,
            0.1 11.33%,
            0.14 13.6%,
            0.25 18.15%,
            0.39 22.7%,
            0.56 27.25%,
            0.77 31.8%,
            1 36.35%,
            0.89 40.9%,
            0.85 43.18%,
            0.81 45.45%,
            0.79 47.72%,
            0.77 50%,
            0.75 52.27%,
            0.75 54.55%,
            0.75 56.82%,
            0.77 59.1%,
            0.79 61.38%,
            0.81 63.65%,
            0.85 65.93%,
            0.89 68.2%,
            1 72.7%,
            0.97 74.98%,
            0.95 77.25%,
            0.94 79.53%,
            0.94 81.8%,
            0.94 84.08%,
            0.95 86.35%,
            0.97 88.63%,
            1 90.9%,
            0.99 93.18%,
            0.98 95.45%,
            0.99 97.73%,
            1 100%) infinite alternate,
        rot 1s ease 4s infinite;
}

@keyframes grow {
    0% {
        /* opacity: 0; */
        scale: 0.9;
    }

    100% {
        /* opacity: 1; */
        scale: 1;
    }
}

@keyframes rot {
    0% {
        rotate: 0turn;
    }

    100% {
        rotate: 0.25turn;
    }
}