.lapnavbar-container {
    top: 0;
    position: fixed;
    top: 0;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70vw;
    margin-top: 2vh;
    background-color: transparent;
    z-index: 100;
    margin-left: 15vw;
    /* display: none; */
}

.lapnavbar-container ul {
    color: white;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(10px);
    border-radius: 1rem;
    border: 2px solid black;
    animation: animateBoxShadow 5s linear infinite;
    cursor: pointer;
}

.lapnavbar-items {
    padding: 0.9vw;
}

.lapnavbar-items a{
    text-decoration: none;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.lapnavbar-items a p {
    white-space: nowrap;
    overflow: hidden;
}

.lapnavbar-items img {
    /* height: 4vh;
    width: 3vw; */
    height: 2rem;
    width: 2rem;
    /* display: none; */
    /* padding-right: 0.5rem; */
    /* filter: invert(1); */
}

.lapnavbar-items :hover {
    scale: 1.1;
}

@keyframes animateBoxShadow {
    0% {
        box-shadow: 0px 0px 10px 10px grey;
    }

    50% {
        box-shadow: 0px 0px 10px 10px white;
    }

    100% {
        box-shadow: 0px 0px 10px 10px grey;
    }
}

@media screen and (max-width: 950px) {
    .lapnavbar-items {
        padding: 0.45vw;
    }

    .lapnavbar-items img {
        height: 1.2rem;
        width: 1.2rem;
    }
}

@media screen and (max-width: 800px) and (min-width: 650px) {
    .lapnavbar-container ul p {
        text-align: center;
    }
}

@media screen and (max-width: 680px) {
    .lapnavbar-items p{
        font-size: 0.85rem;
    }
}

/* #active {
    background-color: #000000a8;
    border-radius: 1rem;
    backdrop-filter: blur(50px);
} */

#active-img{
    /* background-color: #000000a8; */

    /* background-color: black; */
    /* filter: invert(1); */

    border-radius: 25%;
    scale: 1.3;
    animation: active-img-animation 3s linear infinite ;
}


@keyframes active-img-animation {
    0% {
        transform: translatey(0px);
    }

    50% {
        transform: translatey(-0.4vh);
    }

    100% {
        transform: translatey(0px);
    }
}
