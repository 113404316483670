.schedule {
  background: linear-gradient(to bottom, #1e1e2f, #bc2525);
  color: #fff;
  padding: 0;
  margin: 0;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;

}


::-webkit-scrollbar {
  display: none;
}
.schedule-header {
  text-align: center;
}

.schedule-header h1 {
  font-size: 2.5rem;
  color: #ffcccb;
  margin-top: 8%;
  margin-bottom: 1%;
}

@media (max-width: 768px) {
  .schedule-header h1 {
    margin-top: 20px; 
    margin-bottom: 10px; 
  }
}

.schedule-header nav {
  display: flex;
  justify-content: center;
  gap: 2%;
}

.day-tab {
  background: #29293b;
  color: #fff;
  padding: 10px 1.25rem;
  border: none;
  border-radius: 1.25rem;
  cursor: pointer;
  font-size: 1rem;
}

.day-tab.active {
  background: #b44c89;
}

.timeline {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-grow: 1;
}

.timeline-item {
  display: flex;
  align-items: center;
  margin: 20px 0;
  width: 90%;
  max-width: 450px;
}

.circle {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  border: 3px solid white;
  background: var(--secondary-color, #8158a1);
  color: #fff;
  animation: bounce-in 0.6s ease-out;
  text-align: center;
}


.circle::before {
  content: "";
  position: absolute;
  top: 100%; 
  left: 50%;
  transform: translateX(-50%); 
  width: 2px;
  height: 19vh; 
  background: white;
  animation: grow-line 0.8s ease-out forwards;
  opacity: 0;
}

.timeline-item:last-child .circle::before {
  height: 0; 
}

@keyframes grow-line {
  0% {
    width: 0;
    opacity: 0;
  }
  100% {
    width: 2px;
    opacity: 1;
  }
}

.time {
  display: block;
  font-size: 14px;
  color: inherit;
}

.bounce-in {
  animation: bounce-in 0.6s ease-out;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  60% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

.e-card {
  background: #f8f8f0;
  color: #1e1e2f;
  padding: 1.25rem;
  border-radius: 0.625rem;
  margin-left: 20px;
  width: 90%;
  height: 130px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.e-card h2 {
  font-size: 1.2rem;
  margin: 0;
}

.e-card .map-button {
  background: #1e1e2f;
  color: #fff;
  border: none;
  padding: 0.5rem 0.75rem;
  border-radius: 0.625rem;
  cursor: pointer;
  margin: 10px 0;
}

.e-card .time {
  font-weight: bold;
  font-size: 0.9rem;
}

.map-button {
  background: #1e1e2f;
  color: #fff;
  border: none;
  padding: 0.5rem 0.75rem;
  border-radius: 0.625rem;
  cursor: pointer;
  margin: 10px 0;
  transition: background 0.3s;
}

.map-button:hover {
  background: #771289;
}

@media screen and (min-width: 1024px) {
  .timeline-item {
    justify-content: center;
    margin: 30px 0;
    width: 70%;
  }

  .e-card {
    width: 80%;
    max-width: 500px;
  }
}

@media (max-width: 768px) {
  .day-tab {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }

  .timeline-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }

  .circle {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    background: var(--secondary-color, #007bff);
    color: #fff;
    text-align: center;
  }

  .e-card {
    margin-left: 0;
    margin-top: 0;
    width: calc(100% - 60px);
  }

  .circle::before {
    margin-top: 0%;
  }

}
@media (min-width: 801px) and (max-width: 1199px) {
  .schedule-header h1 {
    margin-top: 15%; 
  }
  .circle::before{
    height: 12vh;
  }
}
@media (min-width: 601px) and (max-width: 800px){
.schedule-header h1{
  margin-top: 14%;
}
.circle::before{
  height: 15vh;
}
}
@media(max-width:600px)
{
  .timeline{
    margin-bottom: 15vh;
  }
}
