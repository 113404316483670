*{
    box-sizing: border-box;
}
@font-face {
    font-family: 'CustomFont';
    src: url('./Assets/StarlightRune.ttf') format('truetype');
    font-weight:4000;
    font-style: normal;
}
@font-face {
    font-family: 'CustomFont2';
    src: url('./Assets/AgendaKing.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'CustomFont3';
    src: url('./Assets/Enchanted\ Land.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'CustomFont4';
    src: url('./Assets/Magical\ Star\ Demo.otf') format('opentype');
    font-weight:4000;
    font-style: normal;
}
.acard-head{
    font-family: 'CustomFont3';
    color: black;
    word-spacing: 2px;
}
.acard-des{
    color: black;
    /* letter-spacing: 1px; */
}
.acard-des span{
    /* font-family: 'CustomFont3'; */
    font-size: 18px;
    padding-bottom: 10px;
}


.accontainer{
    margin-bottom: 50px;
    padding-top: 0px;
    margin-right: 105px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.tiltcard{
    width: 45%;
    width: 400px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}   


.accmid{
    padding: 57px 40px 58px 40px;
    height: 400px;
    width: 400px;
    position: relative;
    filter: brightness(1.3) saturate(.8);
    /* filter: 
    drop-shadow(1px 1px 6px gold)
        drop-shadow(1px 1px 28px #74b8dd) ; */
}
@keyframes openScroll{
    0%{

    }
}
/* .accmid::before {
    content: '✨';
    position: absolute;
    top: -10px;
    left: 10px;
    font-size: 1.5em;
    color: gold;
} */
.accontcontainer{

}
/* .accmid:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2), 0 0 15px rgba(255, 215, 0, 0.6);
    transform: translateY(-5px);
    transition: all 0.3s ease;
} */
/* .accmid:hover {
    transform: scale(1.1);
} */

.accontcontainer{
    overflow: auto;
    height: 285px;
}
.accmid img{
    position: absolute;
    top: 0;
}
.acfirst{
    height: 14.2105% !important;
    top: 0;
    left: 0;
    width: 100%;
}
.acsec{
    height: 14.2105% !important;
    bottom: 0!important;
    left: 0;
    width: 100%;
}

.acfullscroll{
    filter: brightness(1.1);
    height: 71.58%;
    left: 0;
    width: 100%;
    z-index: -1;
}

/* .acctop{
    background-image: url("./Scroll3.png");
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 45px;
}
.accbot{
    background-image: url("./Scroll1.png");
    background-position: center;
    background-repeat: no-repeat;
    height: 50px;
    width: 100%;
} */
.acard-head{
    font-size: 2.2rem;
    font-weight: 600;
    text-align: left;
    min-height: 0;
    margin: 0px 0 0px 0;
    text-align: center;
}

.acard-des{
    text-align: center;
    font-size: 1rem;
    margin: 0px 0 0 0;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
}

.acscaleup{
    position: fixed;
    top: calc(50vh - 177.5px);
    left:calc(50vw - 200px);
    width: 400px !important;
    z-index: 5;
}

.scrollbox {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
  }
  
  .scrollbox .content {
    width: calc(100% + 20px);
    padding-right: 25px;
    font-size: 20px;
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
  }
  
  .scrollbox .scrollbar {
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    background-color: transparent;
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
  }
  
  .scrollbox .scrollbar:hover .thumb {
    width: 8px;
  }
  
  .scrollbox .scrollbar::before {
    position: absolute;
    height: 100%;
    left: -20px;
    content: '';
    width: 20px;
    top: 0;
  }
  
  .scrollbox .scrollbar .thumb {
    background-color: #1a110980;
    transition: width 0.15s;
    user-select: none;
    cursor: pointer;
    width: 4px;
    left: 0;
  }
  
  .scrollbox .scrollbar .thumb:active {
    width: 8px;
  }
  
  .modal{
    margin-top: 75px;

  }
.backdrop{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: #000000e1;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;
}

.acmodtilt{
    padding: 0;
    width: 50vw;
    height: 60vh;
    filter: none;
}
.acbigscroll{
    position: absolute;
    top: -10vh !important;
    left: -10vw;
    width: 70vw !important;
    height: 80vh !important;
    z-index: -10 !important;
}
.acmodtilt:hover{
    transform: none !important;
}
.acindicator{
    position: absolute;
    top: 50%;
    left: 50%;
    height: 1px;
    width: 1px;
    z-index: 10;
}
.acindi2{
    position: absolute;
    top: 110vh;
}
.acmiddle{
    margin-top: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 10px !important;
}

.accont-2{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}