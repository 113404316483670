@import url('https://fonts.googleapis.com/css2?family=Almendra:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Emilys+Candy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Emilys+Candy&family=Modern+Antiqua&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.signin_content {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: url('AlbedoBase_XL_create_an_image_of_dark_night_theme_of_a_carniva_0.webp') no-repeat center center fixed;
  background-size: cover;
  width: 100vw;
}

.wrapper {
  width: 500px;
  height: 650px;
  background: url('scroll3.webp') no-repeat;
  background-position: center;
  color: #0b0a0a;
  margin-top: 4em;
  padding: 3.1em 4.3em;
}

.wrapper h1 {
  font-size: 36px;
  text-align: center;
  margin-top: 2.7em;
  font-family: 'Almendra', serif;
  margin-left: -.4em;
}

.signin-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.signin-content .input-box {
  width: 85%;
  height: 35px;
  margin-top: 1em;
  margin-left: 2.9em;
}

.input-box input {
  width: 80%;
  height: 100%;
  outline: none;
  border-radius: 20px;
  font-size: 14px;
  color: #040404;
  padding-left: 8px;
  box-shadow: 0px 0px 8px rgb(194, 121, 3);
}

.input-box input::placeholder {
  color: #080707;
  font-family: "Modern Antiqua";
  font-weight: 590;
}

.custom-google-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  width: 100%; /* Adjust width as needed */
}

.custom-google-login-button:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.google-logo-svg {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.google-login {
  margin-top: .9em;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  scale: 0.8;
}

.google-login button {
  font-size: 0.7rem;
  max-width: 10% !important;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sign-in_google {
  width: 100%;
}

.wrapper .remember-forgot {
  display: flex;
  justify-content: center;
  /* font-size: 14.5px; */
  font-family: "Modern Antiqua";
  font-weight: 590;
}

.shadow-2xl {
  width: 160px !important;
  word-wrap: wrap;
}

.remember-forgot label input {
  accent-color: #fff;
  margin-right: 4px;
  font-family: "Modern Antiqua";
}

.remember-forgot a {
  color: #132ccd;
  text-decoration: none;
}

.remember-forgot a:hover {
  text-decoration: underline;
}

.submit-button {
  width: 100%;
  display: flex;
  justify-content: center;
}

.submit-button button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 40px;
  background: #151414;
  border: none;
  outline: none;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 16px;
  color: #ffffff;
  margin-top: 0.9em;
  font-weight: 590;
}

.wrapper .register-link {
  /* font-size: 14.5px; */
  text-align: center;
  margin: 10px 0 15px;
  font-family: "Modern Antiqua";
}

.register-link p a {
  color: #1f14b9;
  text-decoration: none;
  /* font-weight: 600; */
  font-family: "Modern Antiqua";
}

.register-link p a:hover {
  text-decoration: underline;
}

.reg:hover {
  text-decoration: none;
}

.error {
  width: 80%;
  color: #e63946;
  font-size: 12px;
  justify-content: end;
  display: flex;
  align-items: end;
  justify-content: end;
  font-family: "Modern Antiqua";
}

@media (max-width: 530px) {
  .signin_content {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    position: fixed;
  }

  .nsm7Bb-HzV7m-LgbsSe-BPrWId {
    font-size: 0.7rem;
  }

  .wrapper {
    width: 360px;
    /* height: 550px; */
    background: url('scroll\ 2.1.webp') no-repeat;
    background-position: center;
    overflow: hidden;
    transform: scale(1);
    margin-top: -3.9em;
    background-size: cover;

  }

  .wrapper h1 {
    font-size: 28px;
    margin-top: 3.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: .4em;
  }

  .signin-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  

  .signin-content .input-box {
    width: 100%;
    margin-top: 1.4em;
  }

  .input-box input {
    width: 80%;
    font-size: 12px;
    padding: 12px 25px 12px 12px;
  }

  .submit-button button {
    font-size: 17px;
    height: 2.5rem;
    min-width: 5em;
    margin-top: 1.4em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .submit-button {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .error {
    font-size: 11px;
    display: flex;
    justify-content: end;
    align-items: end;
  }

  .remember-forgot {
    /* font-size: 14px; */
    font-family: "Modern Antiqua";
  }

  .register-link {
    /* font-size: 14px; */
    padding-top: .8em;
    font-family: "Modern Antiqua";
  }
}

.sign-in_google {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #d9d9d9;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.sign-in_google:hover {
  background-color: #f1f1f1;
  border-color: #c0c0c0;
}

.sign-in_google:disabled {
  background-color: #e0e0e0;
  color: #a0a0a0;
  border-color: #d0d0d0;
  cursor: not-allowed;
}

.mr-4 {
  margin-right: 16px;
}