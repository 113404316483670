@import url('https://fonts.googleapis.com/css2?family=Emilys+Candy&family=Modern+Antiqua&display=swap');

*{
  font-family: "Modern Antiqua" ;
  font-weight: 600;
}

body{
  background-color: transparent;
}

.aftermovie-container{
  position: fixed;
  height: 100vh;
  overflow: auto;
}

.loader{
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: min(9vw, 8rem);
  text-align: center;
}

.tv-container {
  position: relative;
  width: 100vw;
  height: 100%;
  margin: 0 auto;
  background-color: black !important;
}

.tv-image {
  width: 100%;
  display: block;
  height: 100vh;
  background-color: black;
}

.small-screen-heading {
  position: absolute;
  left: 50%; 
  transform: translateX(-50%); 
  font-size: 2.5rem;
  color: #fff;
  text-align: center;
  z-index: 10; 
  display: none; 
}

.tv-video {
  position: absolute;
  top: 5%; 
  left:0%; 
  width: 100%; 
  height: 90%; 
  border: none;
}

.vidcont{
  position: absolute;
  top: 13.5%; 
  left:13.15%; 
  width: 75.29%; 
  height: 59%; 
  border: none;
  background-color: black;
}


@media(max-width:480px){
  .small-screen-heading{
    margin-top: 2%;
    font-size: 1.5rem;
    display: block;
  }

  .tv-container iframe{
    height: 50%;
    width: 98%;
    top: 25%;
    left: 1%;
  }
}