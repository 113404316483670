* {
    margin: 0;
    padding: 0;
}

.initial-circular-overlay{
    background-color: transparent;
    position: fixed;
    z-index: 50;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -1.5vb;
    padding: 5px;
}

.navbar-svg{
    border-top-left-radius: 15rem;
    border-top-right-radius: 15rem;
    /* background-color: #000000a6; */
    /* background: linear-gradient(to top, black 60%, transparent 100%); */
    /* animation: pulse 3s linear infinite; */
}

.navbar-svg text{
    fill: black;
    text-shadow: 0 0 15px gold, 0 0 20px gold, 0 0 25px gold;
}

.phone-nav-container {
    position: fixed;
    bottom: -8vb;
    overflow: hidden;
    /* height: 20rem;
    padding-top: 7rem; */
    z-index: 100;
    /* background: linear-gradient(to top, black, transparent); */
    /* box-shadow: 10px 10px 10px 50px #0000006b; */
    /* border: 2px solid black; */
    /* border-radius: 13rem; */
    border-top-left-radius: 15rem;
    border-top-right-radius: 15rem;
    z-index: -1;
    background: radial-gradient(circle );
}

.phone-nav-items-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
    width: 100vw;
    rotate: 180deg;
    border-radius: 100px;
    z-index: 100 !important;
    /* position: relative;
    top: 15vb;
    right: 1vb; */
    position: relative;
    top: 12vb;
}

.phone-nav-overlay-off {
    height: 0;
    width: 0;
    z-index: 1;
    background-color: transparent;
    position: fixed;
}

.phone-nav-overlay-on {
    height: 100vh;
    width: 100vw;
    z-index: 1;
    background-color: transparent;
    position: fixed;
}

.phone-nav-items-container a {
    font-size: 0;
    text-decoration: none;
}

.phone-nav-orb {
    height: 210px;
    width: 210px;
    border: none;
    background-color: transparent;
}

.phone-nav-orb-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100vw; */
    position: fixed;
    bottom: -12vb;
    z-index: 101;
    left: calc(50vw - 105px);
}

.phone-nav-arrow-down {
    position: fixed;
    z-index: 110;
    bottom: -2vb;
    left: 41%;
    animation: arrowUpAnimate 2s ease-in-out infinite;
}

.phone-nav-arrow-down img {
    height: 70px;
    width: 70px;
    /* animation: glowarrowbutton 6s linear infinite; */
}

@keyframes arrowUpAnimate {
    0% {
        transform: translatey(2px);
    }

    50% {
        transform: translatey(-8px);
    }

    100% {
        transform: translatey(2px);
    }
}

@keyframes glowarrowbutton {
    0% {
        box-shadow: 0px 0px 3px 3px #f7de9e;
    }

    33% {
        box-shadow: 0px 0px 5px 5px white;
    }

    66% {
        box-shadow: 0px 0px 7px 7px #f7de9e;
    }

    100% {
        box-shadow: 0px 0px 5px 5px white;
    }
}

.phone-nav-orb-rotation-for-first-half {
    transform: rotate(180deg);
    transition: transform 0.5s;
}

.phone-nav-orb img {
    height: 100%;
    width: 100%;
}

.phone-nav-orb-rotation {
    animation: rotate-the-orb 6s linear infinite;
}

.phone-nav-items {
    position: absolute;
    height: 40px;
    width: 40px;
    background-position: center center;
    background-size: contain;
    border-radius: 10%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: none;
    /* backdrop-filter: blur(20px); */
    cursor: pointer;
    color: white;
    font-size: 1.05rem !important;
    display: none;
    -webkit-line-clamp: 1 !important;
    line-clamp: 1 !important;
}

@keyframes pulse {
    0% {
        box-shadow: 0px 0px 0 0 white;
    }
    
    50% {
        box-shadow: 0px 0px 2vmin 2vmin gold;
    }

    100% {
        box-shadow: 0px 0px 0 0 white;
    }
}


.phone-nav-items img {
    height: 2.2rem;
    width: 2.2rem;
}

.phone-nav-items p {
    font-size: 0.9rem !important;
}

.phone-nav-some-ptags {
    padding-top: 0.2rem;
}

#our-team-p-tag {
    position: relative;
    left: 0.4rem;
    /* white-space: nowrap; */
    /* overflow: hidden; */
}


/* .phone-nav-img-inside-the-orb {
    display: none;
    position: relative;
    bottom: 5vh;
    left: 1vw;
    height: 4rem;
    width: 4rem;
    z-index: 2;
    border: none;
    filter: invert(36%) sepia(7%) saturate(3335%) hue-rotate(127deg) brightness(114%) contrast(94%);
} */

/* #active-img-phone { */
    /* background-color: #000000a8; */

    /* background-color: black; */
    /* filter: invert(1); */

    /* border-radius: 30%; */
    /* scale: 0.9; */
/* } */

#phone-nav-homebutton {
    top: 38px;
    right: 71vw;
}

#phone-nav-accbutton {
    top: -19px;
    left: 34vw;
}

#phone-nav-eventsbutton {
    top: -19px;
    right: 34vw;
}

#phone-nav-merchbutton {
    top: 90px;
    right: 79vw;
}

#phone-nav-gallerybutton {
    rotate: 180deg;
    bottom: 90px;
    left: 79vw;
}

#phone-nav-sponsbutton {
    top: 35px;
    left: 71vw;
}

#phone-nav-morebutton1 {
    top: 90px;
    left: 79vw;
}

#phone-nav-faqsbutton {
    rotate: 180deg;
    bottom: -10px;
    right: 36vw;
}

#phone-nav-teamsbutton {
    rotate: 180deg;
    bottom: 28px;
    right: 70vw;
}

#phone-nav-aftermoviebutton {
    rotate: 180deg;
    bottom: 31px;
    left: 70vw;
}

#phone-nav-gamesbutton {
    rotate: 180deg;
    bottom: -10px;
    left: 36vw;
}

#phone-nav-morebutton2 {
    rotate: 180deg;
    bottom: 90px;
    right: 78vw;
}

@keyframes rotate-the-orb {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 460px) {
    #phone-nav-homebutton {
        top: 26px;
        right: 76vw;
    }

    #phone-nav-accbutton {
        top: -33px;
        left: 32vw;
    }

    #phone-nav-eventsbutton {
        top: -31px;
        right: 30vw;
    }

    #phone-nav-merchbutton {
        top: 86px;
        right: 84vw;
    }

    #phone-nav-sponsbutton {
        top: 26px;
        left: 77vw;
    }

    #phone-nav-morebutton1 {
        top: 86px;
        left: 84vw;
    }

    #phone-nav-faqsbutton {
        bottom: -19px;
        right: 35vw;
    }

    #phone-nav-teamsbutton {
        bottom: 28px;
        right: 73vw;
    }

    #phone-nav-aftermoviebutton {
        bottom: 31px;
        left: 74vw;
    }

    #phone-nav-gamesbutton {
        bottom: -19px;
        left: 33vw;
    }

    #phone-nav-gallerybutton {
        bottom: 84px;
        left: 86vw;
    }

    #phone-nav-morebutton2 {
        bottom: 84px;
        right: 85vw;
    }
}

@media screen and (max-width: 400px) {
    .phone-nav-items p {
        font-size: 0.7rem !important;
    }

    #phone-nav-aftermovie-icon{
        position: relative;
        left: 1vw;
    }

    .phone-nav-container {
        border-top-left-radius: 13rem;
        border-top-right-radius: 13rem;
        padding-top: 1vb;
    }

    .phone-nav-orb {
        height: 190px;
        width: 190px;
    }

    .phone-nav-orb-icon-container {
        left: calc(50vw - 95px);
    }

    .phone-nav-items img {
        height: 2rem;
        width: 2rem;
    }

    .phone-nav-items {
        font-size: 0.8rem !important;
    }

    #phone-nav-homebutton {
        top: 10px;
        right: 73vw;
    }

    #phone-nav-accbutton {
        top: -38px;
        left: 35vw;
    }

    #phone-nav-eventsbutton {
        top: -36px;
        right: 31vw;
    }

    #phone-nav-merchbutton {
        top: 58px;
        right: 82vw;
    }

    #phone-nav-sponsbutton {
        top: 9px;
        left: 75vw;
    }

    #phone-nav-morebutton1 {
        top: 57px;
        right: 82vw;
    }

    #phone-nav-faqsbutton {
        bottom: -29px;
        right: 36vw;
    }

    #phone-nav-teamsbutton {
        bottom: 14px;
        right: 71.5vw;
    }

    #phone-nav-aftermoviebutton {
        bottom: 14px;
        left: 72vw;
    }

    #phone-nav-gamesbutton {
        bottom: -30px;
        left: 35vw;
    }

    #phone-nav-gallerybutton {
        bottom: 59px;
        left: 86vw;
    }

    #phone-nav-morebutton2 {
        bottom: 57px;
        right: 84vw;
    }
}

@media screen and (max-width: 390px) and (max-height: 700px) {
    .phone-nav-container {
        border-top-left-radius: 11rem;
        border-top-right-radius: 11rem;
        padding-top: 3vb;
    }

    .phone-nav-orb {
        height: 170px;
        width: 170px;
    }

    .phone-nav-orb-icon-container {
        left: calc(50vw - 85px);
    }
}

@media screen and (max-width: 370px) {
    .phone-nav-container {
        border-top-left-radius: 13rem;
        border-top-right-radius: 13rem;
        padding-top: 3vb;
    }

    .phone-nav-orb {
        height: 170px;
        width: 170px;
    }

    .phone-nav-orb-icon-container {
        left: calc(50vw - 85px);
    }
}

@media screen and (max-width: 420px) and (max-height: 750px) and (min-width: 405px) and (min-height: 720px) {
    .phone-nav-container {
        border-top-left-radius: 13rem;
        border-top-right-radius: 13rem;
        padding-top: 4vb;
    }

    .phone-nav-orb {
        height: 170px;
        width: 170px;
    }

    .phone-nav-orb-icon-container {
        left: calc(50vw - 85px);
    }
}

@media screen and (max-width: 400px) and (min-width: 385px) and (max-height: 750px) and (min-height: 720px) {
    .phone-nav-container {
        border-top-left-radius: 12.5rem;
        border-top-right-radius: 12.5rem;
        padding-top: 3vb;
    }

    .phone-nav-orb {
        height: 190px;
        width: 190px;
    }

    .phone-nav-orb-icon-container {
        left: calc(50vw - 95px);
    }

    .phone-nav-items-container{
        top: 11vb;
    }
}

@media screen and (max-width: 400px) and (max-height: 720px) {
    .phone-nav-orb {
        height: 175px;
        width: 175px;
    }

    .phone-nav-orb-icon-container{
        left: calc(50vw - 82.5px);
    }   
}

@media screen and (max-width: 400px) and (max-height: 680px) {
    .phone-nav-orb {
        height: 175px;
        width: 175px;
    }

    .phone-nav-orb-icon-container{
        bottom: -14vb;
        left: calc(50vw - 82.5px);
    }   
}

@media screen and (max-width: 400px) and (max-height: 640px) {
    .phone-nav-items-container {
        top: 11vb;
    }
    .phone-nav-container {
        border-top-left-radius: 10rem;
        border-top-right-radius: 10rem;
    }
}

@media screen and (max-width: 400px) and (max-height: 610px) {
    .phone-nav-items-container {
        top: 9vb;
    }

    .phone-nav-container {
        border-top-left-radius: 5rem;
        border-top-right-radius: 5rem;
    }
}