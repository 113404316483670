.cartitems-container {
  width: 100%;
  margin: 0 auto;
  /* background: linear-gradient(to bottom right, #0034a390, #6b1bcc); */
  height: 100vh;
  padding: 20px;
  background-image: url("cart_laptop.webp");
  background-size: 100vw 100vh;
  position: fixed;
}

.cartitems-heading {
  color: black;
  font-size: larger;
  margin-top: 13vh;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}
.cartitems-breaking-line {
  height: 1px;
  background-color: white;
  width: 100%;
  margin-bottom: 2vh;
}
.cartitems-single-item{
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  margin-bottom: 4%;
}
.cartitems::-webkit-scrollbar {
  display: none;
}

.cartitems {
  /* display: flex;
  flex-wrap: wrap;
  justify-content: space-between; */
  height: 80vh;
  overflow: auto;
}
@media (min-width: 600px) {
  .cartitems {
    display: flex;
    flex-direction: column;
    align-items: center;
  justify-content: space-between;
  height: 80vh;
  overflow: auto;
  }
}

.cartitems-all {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.cartitems-single-item {
  width: 95vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
}

.cartitems-format {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cartitems-start {
  display: flex;
  align-items: center;
}
.cartitems-all-data {
  width: 50vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.carticon-product-icon {
  width: 180px;
  height: 220px;
  filter: drop-shadow(0px 0px 10px white);
  /* object-fit: cover; */
  border-radius: 8px;
}

.cartitems-all-data p {
  font-size: 25px;
  margin: 5px 0;
}
.cartitems-all-data p :last-child {
  font-weight: bold;
}


.cartitems-quantity-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cartitems-quantity-reduce,
.cartitems-quantity-increase {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.47);
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
}

.cartitems-quantity {
  color: white;
  font-size: larger;
  width: 40px;
  height: 30px;
  text-align: center;
  border: none;
  background: none;
}

.cartitems-total-price {
  font-weight: bold;
  color: white;
}

.cartitems-down {
  flex: 1;
}

.cartitems-total {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #ddd;
  width: 70vw;
  margin-bottom: 7vh;
}

.cartitems-total h1 {
  color: white;
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.separator {
  height: 1px;
  background-color: #ddd;
  margin: 10px 0;
}

.cartitems-total-item {
  color: white;
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
}

.checkout {
  width: 300px;
  padding: 15px;
  margin-top: 20px;
  background-color: rgb(104, 168, 8);
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  justify-content: space-evenly;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
}

.checkout:hover {
  background-color: rgba(104, 168, 8, 0.7);
}
.cartitems-quantity-remove{
  font-size: 0.1rem;
}

.cartitems-all-data{
  display: flex;
  flex-direction: row;
  gap: 10px
}

.checkout-div{
  width: 100%;
  display: flex;
  justify-content: center;
}

/* Responsive Design */

@media (max-width: 768px) {
  .cartitems {
    flex-direction: column;
  }

  .cartitems-total {
    margin-top: 20px;
  }
}

.terms{
  padding:1rem;
}
.input-checkbox label{
  margin-left: .5rem;
}

@media (max-width: 600px) {
  .cartitems-all-data p {
    font-size: medium;
    margin: 5px 0;
  }
  .cartitems-container {
    background-image: url("cart_phone.webp");
    position: fixed;
  }
  .cartitems-total {
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
    margin-bottom: 14vh;
    width: 88vw;
  }
  .cartitems-start  {
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .cartitems-single-item {
    width: 88vw;
    flex-direction: column;
    flex-direction: row;
    justify-content: space-between;
  }
  .cartitems-heading {
    color: whitesmoke;
    text-shadow: 0 0 5px #ffffff80, 0 0 10px #ff6b6b80, 0 0 15px #ff6b6b80;
    margin-top: 2vh;
  }
  .cartitems-start {
    flex-direction: row;
    align-items: center;
  }

  .cartitems-format {
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  .cartitems-total-price {
    font-size: larger;
    text-align: left;
    margin-top: 10px;
    color: white;
  }

  .checkout {
    font-size: 0.9rem;
  }
  .carticon-product-icon{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 90px;
      width: 70px;
      filter: drop-shadow(0px 0px 10px white);
  }
  .cartitems-all-data{
    width: 47vw;
    font-size: larger;
    color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  }
  .cartitems-quantity {
    color: white;
  }
}

.control-tent-cart{
  margin-bottom: 0.5rem;
}

.control-tent-cart p{
  margin: 0.5rem 0;
}

.text-after-checkout{
  padding: 1rem;
  padding-top: 1.5rem;
  font-size: 1.4rem;
}

.home-delivery-box{
  margin-top: 1rem;
}

.control-tent-cart input {
  margin-right: 0.2rem;
}

.home-delivery-box input {
  margin-right: 0.2rem;
}

.home-delivery-box p{
  margin: 0.5rem 0;
}

.mobile-cont{
  padding: 0.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  justify-content: center;
}

.mobile-numb-input{
  margin-left: 1.5rem;
}

.cont-sub{
  margin: 1rem 0;
}

.your-cart-is-empty{
  display: flex;
  justify-content: center;
  align-items: center;
  color: white !important;
  font-size: 1.5rem ;
}